import { AttendanceData } from '@tymbe/schema/attendance.interface';
import { isDateInCurrentYearAndMonth } from '@tymbe/utils/time';

import { Roles } from './enums';

export const isAttendanceZero = (attendance: AttendanceData) =>
  Number(attendance?.confirmed_time) + Number(attendance?.confirmed_overtime) === 0;

export const getRolesWhichCanDeleteAttendance = (attendance: AttendanceData): Roles[] => {
  const roles: Roles[] = [
    Roles.SUPER_ADMIN,
  ];

  if (isDateInCurrentYearAndMonth(attendance?.application?.shift?.start_time)) {
    roles.push(Roles.TYMBE_ADMIN);

    if (isAttendanceZero(attendance)) {
      roles.push(...[
        Roles.BRANCHOFFICE_MANAGER,
        Roles.COMPANY,
        Roles.TYMBE_COORDINATOR,
      ]);
    }
  }

  return roles;
};
