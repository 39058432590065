import { FormState } from 'informed';

/**
 * Informed forms don't pass "nulled" inputs (changes from value to null).
 * This method checks fields marked as "dirt" (changed value, including to null) against "modified" (changed value)
 * and then adds the nulled inputs as having null value to data
 *
 * @param formState - state of the Informed form, after it's submited
 * @returns modified values including those set to null
 */
export const getValuesForPatch = <P extends object>(formState: FormState<P>): Partial<P> => {
  const nulledEntries: (string | null)[][] = Object.keys(formState.dirt)
    .filter((k) => !Object.keys(formState.modified).includes(k))
    .map((k) => [k, null]);

  const nulledProperties = Object.fromEntries(nulledEntries);

  return {
    ...formState.modified,
    ...nulledProperties,
  };
};
