import moment from 'moment';
import { MomentInput } from 'moment-timezone';

const timezone = 'Europe/Prague';

export const areDatesInSameYearAndMonth = (date1: MomentInput, date2: MomentInput) =>
  date1 && date2 && moment(date1).tz(timezone).isSame(moment(date2).tz(timezone), 'month');

export const isDateInCurrentYearAndMonth = (date: MomentInput) =>
  areDatesInSameYearAndMonth(date, moment());
