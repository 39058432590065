import { Button, Typography } from 'antd';
import PropTypes from 'prop-types';

import styles from './Buttons.module.css';
import { white, darkBlue } from '../../utils/colors';

const { Text } = Typography;

/** @type React.FC<PropTypes.InferType<SubmitButton.propTypes>> */
export const SubmitButton = ({
  buttontext = undefined,
  textstyle = {},
  textcolor = white,
  disabled = false,
  ...props
}) => (
  <Button
    className="ty-button-primary"
    type="primary"
    htmlType="submit"
    disabled={disabled}
    {...props}
  >
    {buttontext && (
      <Text
        style={{ color: disabled ? darkBlue : textcolor, fontWeight: 'bold', ...textstyle }}
        strong
      >
        {buttontext}
      </Text>
    )}
  </Button>
);
SubmitButton.propTypes = {
  buttontext: PropTypes.string,
  // Will be fixed using typescript
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  // Will be fixed using typescript
  // eslint-disable-next-line react/forbid-prop-types
  textstyle: PropTypes.object,
  textcolor: PropTypes.string,
  disabled: PropTypes.bool,
};

/** @type React.FC<PropTypes.InferType<PrimaryButton.propTypes>> */
export const PrimaryButton = ({
  children = undefined,
  textstyle = undefined,
  buttontext = undefined,
  ...props
}) => (
  <Button
    className="ty-button-primary"
    {...props}
    type="primary"
  >
    {children || (buttontext && (
      <Text style={textstyle}>{buttontext}</Text>
    ))}
  </Button>
);
PrimaryButton.propTypes = {
  buttontext: PropTypes.string,
  // Will be fixed using typescript
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  // Will be fixed using typescript
  // eslint-disable-next-line react/forbid-prop-types
  textstyle: PropTypes.object,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]),
};

/** @type React.FC<PropTypes.InferType<SecondaryButton.propTypes>> */
export const SecondaryButton = ({
  children = null,
  buttontext = undefined,
  textstyle = undefined,
  ...props
}) => (
  <Button
    className="ty-button-secondary"
    {...props}
  >
    {children || (buttontext && (
      <Text style={textstyle}>{buttontext}</Text>
    ))}
  </Button>
);
SecondaryButton.propTypes = {
  buttontext: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  // Will be fixed using typescript
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  // Will be fixed using typescript
  // eslint-disable-next-line react/forbid-prop-types
  textstyle: PropTypes.object,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.node,
    PropTypes.element,
    PropTypes.string,
  ]),
};

export const WarningButton = ({ children = null, buttonText = undefined, ...props }) => (
  <button
    type="button"
    {...props}
    className={`ant-btn ty-button-primary ${styles.ty_warning_button}`}
  >
    {children || (buttonText && (
      <Text> {buttonText} </Text>
    ))}
  </button>
);
WarningButton.propTypes = {
  buttonText: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
};

export const DangerButton = ({ children = null, buttontext = undefined, textstyle = undefined, ...props }) => (
  <Button {...props} danger>
    {children || (buttontext && (
      <Text style={textstyle}>{buttontext}</Text>
    ))}
  </Button>
);
DangerButton.propTypes = {
  buttontext: PropTypes.string,
  // Will be fixed using typescript
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  // Will be fixed using typescript
  // eslint-disable-next-line react/forbid-prop-types
  textstyle: PropTypes.object,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
    PropTypes.string,
  ]),
};

export const LinkButton = ({ buttontext, ...props }) => (
  <Button {...props} type="link">
    {buttontext}
  </Button>
);
LinkButton.propTypes = {
  buttontext: PropTypes.string.isRequired,
  // Will be fixed using typescript
  // eslint-disable-next-line react/forbid-prop-types
  textstyle: PropTypes.object,
  onClick: PropTypes.func,
};

export const IconButton = ({ ...props }) => <button {...props} type="button" />;
IconButton.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.element,
  className: PropTypes.string,
};
