import { getConst } from '@tymbe/legislatives/Constants';
import { CzechConstantType } from '@tymbe/legislatives/czechia/CzechConstantType';
import { PaySupplementAmountType, PaySupplementRule, PaySupplementRules } from '@tymbe/schema/pay-supplement.interface';
import { PaySupplementType } from '@tymbe/legislatives/PaySupplementType';
import { Relevant, useFormApi } from 'informed';
import { useState } from 'react';

import TyCheckbox from '../inputs/TyCheckbox';
import TyInput from '../inputs/TyInput';
import TyRadio from '../inputs/TyRadio';
import TyRadioGroup from '../inputs/TyRadioGroup';

interface PaySupplementItemProps {
  name: string;
  label: string;
  type: PaySupplementType;
  isDisabled: boolean;
  initialValues?: PaySupplementRule;
}

interface PaySupplementFormValues {
  $paySupplement: boolean;
  $Holiday: boolean;
  $Weekend: boolean;
  $Night: boolean;
}

const CzechConstantTypeDict = {
  [PaySupplementType.Holiday]: CzechConstantType.MINIMUM_PAY_SUPPLEMENT_HOLIDAY,
  [PaySupplementType.Weekend]: CzechConstantType.MINIMUM_PAY_SUPPLEMENT_WEEKEND,
  [PaySupplementType.Night]: CzechConstantType.MINIMUM_PAY_SUPPLEMENT_NIGHT,
};

function validate(
  value: number,
  amountType: PaySupplementAmountType,
  type: PaySupplementType,
  hourlyRate?: number,
): string | undefined {
  const minValue = getConst(
    CzechConstantTypeDict[type],
    new Date().toISOString(),
    'europe/prague',
  );

  if (!hourlyRate) {
    return undefined;
  }

  if (amountType === PaySupplementAmountType.Percentage && value >= minValue) {
    return undefined;
  }
  if (amountType === PaySupplementAmountType.Fixed && value / hourlyRate >= minValue / 100) {
    return undefined;
  }

  return `Minimální hodnota příplatku je ${minValue} % (${
    hourlyRate * (minValue / 100)
  } Kč)`;
}

const PaySupplementItem = ({
  name,
  label,
  type,
  initialValues,
  isDisabled,
}: PaySupplementItemProps) => {
  const [percent, setPercent] = useState(
    initialValues?.type === PaySupplementAmountType.Percentage,
  );
  const formApi = useFormApi();
  const disabledClassName = isDisabled ? 'ty_input_field--is-disabled' : '';

  return (
    <div>
      <TyCheckbox
        name={`$${type}`}
        label={label}
        className="mb-1.5 mt-3"
        disabled={isDisabled}
        defaultValue={Boolean(initialValues)}
      />
      <Relevant<PaySupplementFormValues>
        when={({ formState }) => formState.values[`$${type}`]}
      >
        <div className="flex flex-wrap gap-3 items-center">
          <div className="w-1/2 relative">
            <TyInput
              className="w-full"
              label="Příplatek"
              name={`${name}.value`}
              type="number"
              required="Povinné pole"
              disabled={isDisabled}
              validate={(value, values) => validate(
                value,
                (values.pay_supplement as PaySupplementRules)[type]?.type as PaySupplementAmountType,
                type,
                values.payment_base as number,
              )}
              defaultValue={initialValues?.value}
            />
            {percent && <span className="absolute bottom-3 right-12">%</span>}
          </div>
          <div className="flex flex-wrap flex-col flex-1 gap-1">
            <TyRadioGroup
              className="ty-radio-group"
              name={`${name}.type`}
              onChange={(fieldState) => {
                setPercent(fieldState.value === PaySupplementAmountType.Percentage);
                formApi.validate();
              }}
              defaultValue={initialValues?.type ?? PaySupplementAmountType.Fixed}
              disabled={isDisabled}
            >
              <TyRadio
                value={PaySupplementAmountType.Fixed}
                label="Fixní částka"
                className={disabledClassName}
                checked
                disabled={isDisabled}
              />
              <TyRadio
                value={PaySupplementAmountType.Percentage}
                label="V procentech"
                className={disabledClassName}
                disabled={isDisabled}
              />
            </TyRadioGroup>

            <TyCheckbox
              name={`${name}.applies_to_entire_shift`}
              label="Platí na celou směnu"
              className="mb-1"
              defaultValue={initialValues?.applies_to_entire_shift}
              disabled={isDisabled}
            />
          </div>
        </div>
      </Relevant>
    </div>
  );
};

export default PaySupplementItem;
