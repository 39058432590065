const columns = [
  {
    value: 'credit_payout',
    label: 'Typ',
    sortable: true,
  },
  {
    value: 'sortByPersonId',
    label: 'ID Tymber',
    sortable: true,
  },
  {
    value: 'sortByPersonFirstName',
    label: 'Jméno',
    sortable: true,
  },
  {
    value: 'sortByPersonLastName',
    label: 'Příjmení',
    sortable: true,
  },
  {
    value: 'bank_acc',
    label: 'Číslo účtu',
    sortable: false,
  },
  {
    value: 'bank_code',
    label: 'Kód banky',
    sortable: false,
  },
  {
    value: 'sortByCompanyName',
    label: 'Firma',
    sortable: true,
  },
  {
    value: 'amount',
    label: 'K vyplacení',
    sortable: true,
  },
  {
    value: 'created_at',
    label: 'Datum požadavku',
    sortable: true,
  },
  {
    value: 'sortByExportedAt',
    label: 'Datum zpracování',
    sortable: true,
  },
];

export default columns;
