import { CountryCodes, ForeignerOrigin } from '@tymbe/schema/enums';
import { FormApi, FormState, useFormApi, useFormState } from 'informed';
import { useState } from 'react';

import { foreignerOptions, genderOptions } from './detailFormOptions';
import { EditUserFormData, CountryOptionType } from './editUserForm.interface';
import { useUser } from '../../../apiClient/ApiContext';
import Protect from '../../../apiClient/Protect';
import TyCheckbox from '../../../components/inputs/TyCheckbox';
import TyDateInput from '../../../components/inputs/TyDateInput';
import TyInput from '../../../components/inputs/TyInput';
import { TySelect } from '../../../components/inputs/TySelect';
import TySelectCountry from '../../../components/inputs/TySelectCountry/TySelectCountry';
import TyTextArea from '../../../components/inputs/TyTextArea';
import validateAddress from '../../../components/inputs/Validators/address';
import validateBankAccount from '../../../components/inputs/Validators/bankAccount';
import validateEmail from '../../../components/inputs/Validators/email';
import validatePhoneNumber from '../../../components/inputs/Validators/phoneNumber';
import validateZipCode from '../../../components/inputs/Validators/zipCode';
import { euEeaSwitzCountryCodeNames } from '../../../types/utils';
import { Roles } from '../../../utils/enums';
import { blockInvalidCharactersForZip, formatOnPasteForZip } from '../../../utils/zipCode';

const UserDetailForm = () => {
  const user = useUser();
  const formState: FormState<EditUserFormData> = useFormState();
  const formApi: FormApi<EditUserFormData> = useFormApi();

  const isSuperOrTymbeAdmin = user.role.some((role) => [
    Roles.SUPER_ADMIN,
    Roles.TYMBE_ADMIN,
  ].includes(role.slug));

  const countryOptions = Object.entries(euEeaSwitzCountryCodeNames()).map(([code, name]) => ({
    label: name, value: code,
  }));

  const canEditEmail = user.role.some((role) => [
    Roles.SUPER_ADMIN,
    Roles.TYMBE_ADMIN,
    Roles.ADMIN,
  ].includes(role.slug));

  const [contactCountry, setContactCountry] = useState<CountryCodes | null>(null);
  const [permanentCountry, setPermanentCountry] = useState<CountryCodes | null>(null);

  const isCZE = formState.values.nationality?.value === CountryCodes.CZE;

  const isEUFamilyCitizen = formState.values.foreigner_origin?.value === ForeignerOrigin.EU_FAMILY_CITIZEN;

  const onNationalityChange = (nationalityValue: { value: CountryOptionType }) => {
    if (!nationalityValue.value || nationalityValue.value.value === CountryCodes.CZE) {
      formApi.setValue('foreigner_origin', null);
    }
  };

  const onForeginerChange = (foreginerValue: { value: { label: string, value: ForeignerOrigin } }) => {
    if (!foreginerValue.value || foreginerValue.value.value !== ForeignerOrigin.EU_FAMILY_CITIZEN) {
      formApi.setValue('family_member_nationality', null);
    }
  };

  // TODO: add format checks to country name, phone etc.
  return (
    <div>
      <h2 className="ty-h2 text-align-start">
        Osobní údaje
      </h2>
      <div className="flex lg:flex-row items-stretch flex-col gap-28">
        <div className="flex flex-col gap-5 w-full">
          <h3 className="ty-h3 text-align-start">
            Základní
          </h3>
          <div className="flex flex-row gap-2 flex-wrap">
            <TyInput
              name="first_name"
              label="Jméno"
              className="w-full md:flex-1"
              required
            />
            <TyInput
              name="last_name"
              label="Příjmení"
              className="w-full md:flex-1"
              required
            />
          </div>
          <TyInput
            name="login_email"
            label="Přihlašovací email"
            validate={validateEmail}
            required
            disabled={!canEditEmail}
          />

          <h5 className="ty-h5 text-align-start">
            Trvalé bydliště
          </h5>
          <TyInput
            name="permanent_address"
            label="Adresa"
            validate={(value: string, values) => validateAddress(value, values.permanent_country)}
            validateWhen={['permanent_country']}
          />
          <div className="flex flex-row gap-2 flex-wrap">
            <TyInput
              name="permanent_locality"
              label="Město"
              className="w-full md:flex-1"
            />
            <TyInput
              name="permanent_zip"
              label="Směrovací číslo"
              className="w-full md:flex-1"
              validate={(value) => (permanentCountry ? validateZipCode(value as string, permanentCountry) : undefined)}
              onPaste={formatOnPasteForZip}
              onKeyDown={blockInvalidCharactersForZip}
            />
          </div>
          <TySelectCountry
            name="permanent_country"
            label="Země"
            validate={
              (value: string, values: { permanent_address: string; }) =>
                validateAddress(value, values.permanent_address)
            }
            validateWhen={['permanent_address']}
            onChange={({ value }: { value: CountryOptionType }) => setPermanentCountry(value.value)}
          />
          <Protect
            auth={[
              Roles.SUPER_ADMIN,
              Roles.TYMBE_ADMIN,
              Roles.TYMBE_COORDINATOR,
              Roles.ADMIN,
            ]}
            redirect={false}
          >
            <h5 className="ty-h5 text-align-start">
              Kontaktní adresa
            </h5>
            <TyInput
              name="contact_address"
              label="Adresa"
              validate={(value: string, values) => validateAddress(value, values.contact_country)}
              validateWhen={['contact_country']}
            />
            <div className="flex flex-row gap-2 flex-wrap">
              <TyInput
                name="contact_locality"
                label="Město"
                className="w-full md:flex-1"
              />
              <TyInput
                name="contact_zip"
                label="Směrovací číslo"
                className="w-full md:flex-1"
                validate={(value) => (contactCountry ? validateZipCode(value as string, contactCountry) : undefined)}
                onPaste={formatOnPasteForZip}
                onKeyDown={blockInvalidCharactersForZip}
              />
            </div>
            <TySelectCountry
              name="contact_country"
              label="Země"
              validate={
                (value: string, values: { contact_address: string; }) => validateAddress(value, values.contact_address)
              }
              validateWhen={['contact_address']}
              onChange={({ value }: { value: CountryOptionType }) => setContactCountry(value.value)}
            />
          </Protect>
        </div>
        <div className="flex flex-col gap-5 w-[100%]">
          <h3 className="ty-h3 text-align-start">
            Rozšířené
          </h3>
          <div className="flex flex-row gap-2 flex-wrap">
            <TySelect
              name="gender"
              label="Pohlaví"
              className="w-full md:flex-1"
              options={genderOptions}
            />
            <TyDateInput
              picker="date"
              name="birthdate"
              className="w-full md:flex-1"
              placeholder="Datum narození"
            />
          </div>
          <TyInput
            name="birthplace"
            label="Místo narození"
            className="w-full md:flex-1"
          />
          <div className="flex flex-row gap-2 flex-wrap">
            <TySelectCountry
              name="nationality"
              label="Národnost"
              className="w-full md:flex-1"
              onChange={(nationalityValue: { value: CountryOptionType; }) => onNationalityChange(nationalityValue)}
            />
            <TySelect
              id="foreigner_select"
              name="foreigner_origin"
              className="w-full md:flex-1"
              label="Důvod pobytu cizince"
              options={foreignerOptions}
              onChange={(foreginerValue) => onForeginerChange(foreginerValue)}
              isDisabled={!isSuperOrTymbeAdmin || isCZE}
            />
          </div>
          <TySelect
            id="family_member_nationality"
            name="family_member_nationality"
            label="Stát původu rodinného příslušníka"
            className="w-full md:flex-1"
            options={countryOptions}
            isDisabled={!isSuperOrTymbeAdmin || !isEUFamilyCitizen}
            required={isEUFamilyCitizen}
          />
          <TyInput
            name="pin"
            label="Rodné číslo"
            className="w-full md:flex-1"
          />
          <h3 className="ty-h3 text-align-start">
            Kontaktní údaje
          </h3>
          <TyInput
            name="phone"
            label="Telefon"
            validate={validatePhoneNumber}
            validateOn="change"
          />
          <Protect
            auth={[
              Roles.SUPER_ADMIN,
              Roles.TYMBE_ADMIN,
              Roles.TYMBE_COORDINATOR,
              Roles.ADMIN,
            ]}
            redirect={false}
          >
            <h3 className="ty-h3 text-align-start">
              Platební údaje
            </h3>
            <TyInput
              name="bank_account"
              label="Bankovní účet"
              validate={validateBankAccount}
              disabled={!isSuperOrTymbeAdmin}
            />
            <TyTextArea
              name="payment_note"
              label="Poznámka k platbě"
              disabled={!isSuperOrTymbeAdmin}
            />
          </Protect>
          <Protect
            auth={[
              Roles.SUPER_ADMIN,
              Roles.TYMBE_ADMIN,
              Roles.ADMIN,
              Roles.TYMBE_COORDINATOR,
            ]}
            redirect={false}
          >
            <TyCheckbox
              name="salary_limit_enabled"
              label="Zabránit překročení rozhodného příjmu u DPČ"
            />
          </Protect>
          <Protect
            auth={[
              Roles.SUPER_ADMIN,
              Roles.TYMBE_ADMIN,
              Roles.ADMIN,
              Roles.TYMBE_COORDINATOR,
              Roles.COMPANY,
            ]}
            redirect={false}
          >
            <TyCheckbox
              name="work_on_hpp_allowed"
              label="Povolit práci na HPP"
              disabled={!isSuperOrTymbeAdmin}
            />
          </Protect>
        </div>
      </div>
    </div>
  );
};
export default UserDetailForm;
