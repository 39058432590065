import { Component } from 'react';

type SmartsuppFuntion = (command: string) => void;

interface WindowWithSmartsupp extends Window {
  smartsupp?: SmartsuppFuntion;
}

export default class Smartsupp extends Component {
  componentDidMount(): void {
    const { smartsupp } = (window as WindowWithSmartsupp);
    if (smartsupp && import.meta.env.MODE !== 'test') {
      setTimeout(() => {
        smartsupp('chat:show');
      }, 1000);
    }
  }

  componentWillUnmount(): void {
    const { smartsupp } = (window as WindowWithSmartsupp);
    if (smartsupp) {
      smartsupp('chat:hide');
    }
  }

  render() {
    return (
      <div />
    );
  }
}
