import { PaySupplementAmountType, PaySupplementRules } from '@tymbe/schema/pay-supplement.interface';
import { UtilityData } from '@tymbe/schema/utility.interface';
import {
  FieldState,
  FormProps,
  InformedProps,
  RelevantParams,
  useFormApi,
  useFormState,
} from 'informed';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import styles from './ShiftTemplateForm.module.css';
import feathersClient from '../../../../../apiClient';
import { useUser } from '../../../../../apiClient/ApiContext';
import Protect from '../../../../../apiClient/Protect';
import TyAsyncSelect from '../../../../../components/inputs/TyAsyncSelect';
import TyCheckbox from '../../../../../components/inputs/TyCheckbox';
import TyDateInput from '../../../../../components/inputs/TyDateInput';
import TyInput from '../../../../../components/inputs/TyInput';
import TyMarkdownEditor from '../../../../../components/inputs/TyMarkdownEditor';
import { TySelect } from '../../../../../components/inputs/TySelect';
import TySelectBranchoffice from '../../../../../components/inputs/TySelectBranchoffice';
import TySelectIscoClassification from '../../../../../components/inputs/TySelectIscoClassification';
import TySelectNaceClassification from '../../../../../components/inputs/TySelectNaceClassification';
import TySelectPosition from '../../../../../components/inputs/TySelectPosition';
import TySelectRequirements from '../../../../../components/inputs/TySelectRequirements';
import { Option } from '../../../../../components/inputs/TySelectRequirements/TySelectRequirements.types';
import TySelectUtility from '../../../../../components/inputs/TySelectUtility/TySelectUtility';
import validateHppMaxTrial from '../../../../../components/inputs/Validators/hppMaxTrial';
import validateHppWeeklyHours from '../../../../../components/inputs/Validators/hppWeeklyHours';
import PaySupplement from '../../../../../components/PaySupplement/PaySupplement';
import {
  DocumentTypeData,
  DocumentTypeEnum,
  PerkData,
  ShiftTemplateData,
  isDocumentTypeData,
} from '../../../../../types/TymbeApi';
import { DEFAULT_BILLING_RATE } from '../../../../../utils/constants';
import { Roles } from '../../../../../utils/enums';
import { shiftLabelsOptions } from '../utils/ShiftTemplate.utils';
import EmojiPicker from '../../../../calendar/components/EmojiPicker';

type JobClassification = {
  id: string;
  name: string;
};
export interface ShiftTemplateFormValues extends ShiftTemplateData {
  _shift_template_type?: (typeof shiftTemplateTypeOptions)[0];
  requirements?: (DocumentTypeData | PerkData)[] | null;
  utility?: UtilityData[] | null;
  pausePublish?: boolean;
  $paySupplement?: boolean;
  $Holiday?: boolean;
  $Weekend?: boolean;
  $Night?: boolean;
  job_classification_isco: JobClassification;
  job_classification_nace?: JobClassification;
  shiftLabels?: {
    label: string,
    value: string
  }[] | null
}

type OwnProps = {
  shiftTemplate?: ShiftTemplateData;
  companyId?: string | number;
};

type ShiftTemplateFormProps = InformedProps<
FormProps & OwnProps,
ShiftTemplateFormValues
>;

const shiftTemplateTypeOptions = [
  { value: 'company', label: 'Pro celou firmu' },
  { value: 'department', label: 'Pro vybrané oddělení' },
];

const defaultPaySupplement: PaySupplementRules = {
  Holiday: {
    applies_to_entire_shift: false,
    type: PaySupplementAmountType.Percentage,
    value: 100,
  },
  Weekend: {
    applies_to_entire_shift: false,
    type: PaySupplementAmountType.Percentage,
    value: 10,
  },
  Night: {
    applies_to_entire_shift: false,
    type: PaySupplementAmountType.Percentage,
    value: 10,
  },
};

const ShiftTemplateForm = ({
  shiftTemplate,
  companyId,
}: ShiftTemplateFormProps) => {
  const formApi = useFormApi<ShiftTemplateFormValues>();
  const formState = useFormState<ShiftTemplateFormValues>();
  const user = useUser();

  const isAdmin = user.hasRoles([
    Roles.SUPER_ADMIN,
    Roles.TYMBE_ADMIN,
    Roles.ADMIN,
    Roles.TYMBE_COORDINATOR,
  ]);

  const isSuperAdmin = user.hasRoles([Roles.SUPER_ADMIN]);

  const isSuperTymbeCompanyAdmin = user.hasRoles([
    Roles.SUPER_ADMIN,
    Roles.TYMBE_ADMIN,
    Roles.COMPANY,
  ]);

  const isTymbeAdmin = user.hasRoles([Roles.TYMBE_ADMIN]);

  const { data: company } = useQuery(['FetchCompany', companyId], async () => {
    if (!companyId) return Promise.reject();
    const result = await feathersClient.service('company').get(companyId, {
      query: { $eager: '[address, contactPerson, contractor]' },
    });
    return result;
  });

  const [billingCoef, setBillingCoef] = useState<string | number>(0);
  const [containHPP, setContainHPP] = useState<boolean>(Boolean(shiftTemplate?.expected_hpp_weekly_hours));

  // Recalculate Billing coefficient
  useEffect(() => {
    const billingRate = formState.values.billing_rate;
    const paymentBase = formState.values.payment_base;
    const coef = Number(billingRate) / Number(paymentBase);
    setBillingCoef(!coef ? 0 : coef.toFixed(2));
  }, [formState.values.billing_rate, formState.values.payment_base]);

  // Recalculate default values of `margin`, `billing_rate` and `credits`
  const onPaymentBaseChange = ({ value: paymentBase }: FieldState<number>) => {
    const margin = Number((paymentBase * 0.2).toFixed(2));
    const billingRate = Number((paymentBase * DEFAULT_BILLING_RATE).toFixed(2));
    const credits = Number((billingRate - paymentBase - margin).toFixed(0));

    formApi.setValue('margin', margin);
    formApi.setValue('billing_rate', billingRate);
    formApi.setValue('credits', credits);
    formApi.validate();
  };

  const onCreditsChange = ({ value: credits }: FieldState<number>) => {
    const invitationCredits = credits / 2;
    formApi.setValue(
      'invitation_credits',
      Number(invitationCredits.toFixed(0)),
    );
  };

  const onRequirementsChange = ({ value }: FieldState<Option | Option[]>) => {
    if (!Array.isArray(value)) {
      if (
        isDocumentTypeData(value)
        && value.type === DocumentTypeEnum.CONTRACT_HPP
      ) {
        setContainHPP(true);
      }
      return;
    }

    const containsHpp = value.some(
      (option) =>
        isDocumentTypeData(option)
        && option.type === DocumentTypeEnum.CONTRACT_HPP,
    );
    setContainHPP(containsHpp);
  };

  const onPausePublish = ({ value: paused }: FieldState<boolean>) => {
    const publishedDate = shiftTemplate?.publish_at ? moment(shiftTemplate?.publish_at) : moment();
    formApi.setValue('publish_at', paused ? null : publishedDate);
  };

  const validateCreditsMin = (creditsMin: number) => {
    if (creditsMin <= formApi.getValue('credits')) return undefined;

    return 'Minimální hodnota musí být menší než maximální.';
  };

  const getInitialPublishedAtValue = (
    template: ShiftTemplateData | undefined,
  ) => {
    if (!template) return moment();

    return template?.publish_at ? moment(template?.publish_at) : undefined;
  };

  // Show spinner on first load. We don't want to show empty form.
  return (
    <div>
      <div>
        <div className="flex flex-col gap-5">
          <h3>Základní údaje</h3>
          <div className="flex align-center gap-2">
            <EmojiPicker className="bg-secondary-50 w-12 h-12 text-[18px] leading-[18px] border rounded-xl" name="emoji" />
            <TyInput
              name="template_name"
              label="Název"
              required="Povinné pole"
              className="flex-1"
              disabled={!isSuperAdmin && company?.is_readonly}
            />
          </div>

          <TyInput name="name" label="Alias / Název pro brigádníky" />

          <TySelect
            name="_shift_template_type"
            type="text"
            label="Typ"
            initialValue={
              shiftTemplateTypeOptions[
                shiftTemplate?.branchoffice?.length ? 1 : 0
              ]
            }
            options={shiftTemplateTypeOptions}
            openMenuOnFocus
            isDisabled={!isSuperAdmin && company?.is_readonly}
          />

          <TySelectBranchoffice
            companyId={companyId || shiftTemplate?.company_id}
            type="department"
            relevant={({
              formState: fs,
            }: RelevantParams<ShiftTemplateFormValues>) =>
              fs.values._shift_template_type?.value === 'department'}
            relevanceWhen={['_shift_template_type']}
            name="branchoffice"
            label="Přidělené oddělení"
            isMulti
            required
            isClearable
            isDisabled={!isSuperAdmin && company?.is_readonly}
          />

          <TySelectRequirements
            name="requirements"
            label="Podmínky"
            companyId={companyId || shiftTemplate?.company_id}
            isMulti
            isClearable
            userRole={user.role}
            isDisabled={!isSuperTymbeCompanyAdmin}
            onChange={onRequirementsChange}
          />

          <TySelectUtility
            name="utility"
            label="Pomůcky"
            isMulti
            isClearable
            isDisabled={!isSuperTymbeCompanyAdmin}
          />

          <TySelectIscoClassification
            name="job_classification_isco"
            label="Klasifikace ISCO"
            fulfillmentType={company?.fulfillment_type}
            required="Povinné pole"
            isDisabled={!isSuperAdmin && !isTymbeAdmin}
          />

          <TySelectNaceClassification
            name="job_classification_nace"
            label="Klasifikace NACE"
            isClearable
            isDisabled={!isSuperAdmin && !isTymbeAdmin}
          />

          <Protect auth={[Roles.SUPER_ADMIN]} redirect={false}>
            <TySelectPosition name="position" label="Tymbe pozice" />
          </Protect>

          <TyAsyncSelect
            isMulti
            name="shiftLabels"
            label="Vlastnosti směny"
            loadOptions={() => ({
              options: shiftLabelsOptions,
              hasMore: false,
            })}
            isDisabled={!isSuperAdmin && !isTymbeAdmin}
          />

          <TyDateInput
            name="publish_at"
            label="Datum zveřejnění"
            minuteStep={5}
            picker="date"
            highlightHolidays
            disabled={formState.values.pausePublish || !isSuperAdmin || company?.is_readonly}
            initialValue={getInitialPublishedAtValue(shiftTemplate)}
          />

          <TyCheckbox
            id="pausePublish"
            name="pausePublish"
            label="Pozastavit zveřejnění"
            onChange={onPausePublish}
            disabled={!isSuperAdmin || company?.is_readonly}
            initialValue={shiftTemplate?.publish_at === null}
          />
        </div>

        <div className={styles.column}>
          <h3>Peníze</h3>
          <TyInput
            label="Hodinová odměna"
            name="payment_base"
            type="number"
            onChange={onPaymentBaseChange}
            required="Povinné pole"
          />
          <Protect
            auth={[Roles.SUPER_ADMIN, Roles.TYMBE_ADMIN]}
            redirect={false}
          >
            <div className={[styles.row, styles.centered].join(' ')}>
              <TyInput
                required="Povinné pole"
                name="billing_rate"
                type="number"
                label="Fakturační tarif"
              />
              <div>Koeficient: {billingCoef}</div>
            </div>
            <TyInput
              required="Povinné pole"
              name="margin"
              type="number"
              label="Marže"
              relevant={() => isAdmin}
            />
          </Protect>
          {!isAdmin ? null : (
            <>
              <h3>Kreditové bonusy</h3>
              <TyInput
                label="Minimální"
                name="credits_min"
                type="number"
                required="Povinné pole"
                defaultValue={0}
                relevant={() => isAdmin}
                validate={validateCreditsMin}
              />
              <TyInput
                label="Maximální"
                name="credits"
                type="number"
                onChange={onCreditsChange}
                required="Povinné pole"
                relevant={() => isAdmin}
              />

              <TyInput
                required="Povinné pole"
                name="invitation_credits"
                type="number"
                label="Pozvánky"
                relevant={() => isAdmin}
              />
            </>
          )}
        </div>
      </div>

      <div className="relative my-5">
        <PaySupplement
          name="pay_supplement"
          isDisabled={false}
          initialValues={shiftTemplate?.pay_supplement ?? defaultPaySupplement}
        />
      </div>

      {containHPP && (
        <>
          <h3>HPP</h3>
          <div className={styles.row}>
            <TyInput
              className={styles.column}
              required="Povinné pole"
              name="expected_hpp_weekly_hours"
              type="number"
              label="Týdenní úvazek na HPP (v hodinách)"
              validate={validateHppWeeklyHours}
              relevant={() => isAdmin}
            />

            <TyInput
              className={styles.column}
              required="Povinné pole"
              name="maximum_hpp_trial_period"
              type="number"
              label="Maximální zkušební doba (ve dnech)"
              validate={validateHppMaxTrial}
              relevant={() => isAdmin}
            />
          </div>
        </>
      )}

      <h3>Doplňující informace</h3>

      <TyMarkdownEditor
        name="description"
        label="Popis pozice"
        initialValue={shiftTemplate?.description}

      />
    </div>
  );
};

export default ShiftTemplateForm;
