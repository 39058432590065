import { ConstantRecord } from "../src/ConstantRecord";
import { ConstantType } from "../src/ConstantType";
import { CzechConstantType } from "../src/czechia/CzechConstantType";

// Please keep records sorted by date (desc) for better readability and speed.

export const constantConfig: Record<ConstantType, ConstantRecord[]> = {

  // Czech Republic
  [CzechConstantType.MINIMUM_WAGE]: [
    { validSince: '2024-01-01 00:00', value: 18900 },
    { validSince: '2023-01-01 00:00', value: 17300 },
    { validSince: '2022-01-01 00:00', value: 16200 },
  ],
  [CzechConstantType.MINIMUM_PAY_SUPPLEMENT_HOLIDAY]: [
    { validSince: '2023-01-01 00:00', value: 100 },
  ],
  [CzechConstantType.MINIMUM_PAY_SUPPLEMENT_WEEKEND]: [
    { validSince: '2023-01-01 00:00', value: 10 },
  ],
  [CzechConstantType.MINIMUM_PAY_SUPPLEMENT_NIGHT]: [
    { validSince: '2023-01-01 00:00', value: 10 },
  ],
  [CzechConstantType.DPP_LIMIT]: [
    { validSince: '2017-01-01 00:00', value: 10000 },
  ],
  [CzechConstantType.DPC_LIMIT]: [
    { validSince: '2023-01-01 00:00', value: 4000 },
    { validSince: '2021-01-01 00:00', value: 3500 },
    { validSince: '2019-01-01 00:00', value: 3000 },
    { validSince: '2016-01-01 00:00', value: 2500 },
  ],
  [CzechConstantType.DPC_ADVANCE_LIMIT]: [
    { validSince: '2021-01-01 00:00', value: 1000 },
  ],
  [CzechConstantType.SLEVA_NA_POPLATINKA]: [
    { validSince: '2021-01-01 00:00', value: 2570 },
  ],
  [CzechConstantType.SLEVA_NA_STUDENTA]: [
    { validSince: '2024-01-01 00:00', value: 0 },
    { validSince: '2021-01-01 00:00', value: 335 },
  ],
  [CzechConstantType.SLEVA_INVALIDNI_DUCHOD]: [
    { validSince: '2021-01-01 00:00', value: 210 },
  ],
  [CzechConstantType.SLEVA_INVALIDNI_DUCHOD_III]: [
    { validSince: '2021-01-01 00:00', value: 420 },
  ],
  [CzechConstantType.SLEVA_ZTPP]: [
    { validSince: '2021-01-01 00:00', value: 1345 },
  ],
  [CzechConstantType.ZIVOTNI_MINIMUM]: [
      { validSince: '2021-01-01 00:00', value: 4860 },
  ],
  [CzechConstantType.NORMATIVNI_NAKLADY_NA_BYDLENI]: [
    { validSince: '2021-01-01 00:00', value: 15597 },
  ],
  [CzechConstantType.NEZABAVITELNA_CASTKA]: [
    { validSince: '2024-01-01 00:00', value: 12705 },
    { validSince: '2023-01-01 00:00', value: 13638 },
  ],
  [CzechConstantType.ZAKLADNI_SOCIALNI_POJISTENI]: [
    { validSince: '2024-01-01 00:00', value: 7.1 },
    { validSince: '2017-01-01 00:00', value: 6.5 },
  ],
  [CzechConstantType.ZAKLADNI_ZDRAVOTNI_POJISTENI]: [
    { validSince: '2017-01-01 00:00', value: 4.5 },
  ],
  [CzechConstantType.ZDRAVOTNI_POJISTENI_PLACENE_ZAMESTNAVATELEM]: [
    { validSince: '2017-01-01 00:00', value: 9 },
  ],
  [CzechConstantType.ZALOHOVA_DAN]: [
    { validSince: '2017-01-01 00:00', value: 15 },
  ],
  [CzechConstantType.SRAZKOVA_DAN]: [
    { validSince: '2017-01-01 00:00', value: 15 },
  ],
};
