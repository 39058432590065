export const mpsvOldTableColumnHeaders = [
  {
    value: 'application.employer_id',
    label: 'Company',
    sortable: true,
  },
  {
    value: 'application:person.last_name',
    label: 'Tymber',
    sortable: true,
  },
  {
    value: 'status',
    label: 'Status',
    sortable: true,
  },
  {
    value: 'created_at',
    label: 'Created at',
    sortable: true,
  },
];

export const mpsvTableColumnHeaders = [
  {
    value: '',
    label: 'Stav',
    sortable: false,
  },
  {
    value: 'person_id',
    label: 'Tymber ID',
    sortable: true,
  },
  {
    value: 'person.last_name',
    label: 'Tymber',
    sortable: true,
  },
  {
    value: 'employer_id',
    label: 'Company',
    sortable: true,
  },
  {
    value: 'shift.start_time',
    label: 'Nástup',
    sortable: true,
  },
  {
    value: 'shift:branchoffice.parent_id',
    label: 'Pracoviště',
    sortable: true,
  },
  {
    value: 'mpsvReport.status',
    label: 'Status',
    sortable: true,
  },
  {
    value: 'mpsvReport.created_at',
    label: 'Vznik hlášení',
    sortable: true,
  },
  {
    value: '',
    label: '',
    sortable: false,
  },
];
