import { useFormState, useFormApi } from 'informed';
import { useEffect } from 'react';

import TyInput from '../../../../components/inputs/TyInput';
import TySelectBranchoffice from '../../../../components/inputs/TySelectBranchoffice';
import TySelectRole from '../../../../components/inputs/TySelectRole/TySelectRole';
import validatePhoneNumber from '../../../../components/inputs/Validators/phoneNumber';
import { BranchofficeData, LoginData } from '../../../../types/TymbeApi';
import { Roles } from '../../../../utils/enums';
import { addPlusToPhone } from '../../../../utils/phoneNumber';

export interface UserFormValues extends LoginData {
  re_password: string,
  position?: string,
  assigned_branchoffices: BranchofficeData[],
  phone?: string,
}

type UserFormProps = {
  companyId: number | string,
  user?: LoginData
};

const UserForm = ({ companyId, user = undefined }: UserFormProps) => {
  const formState = useFormState<UserFormValues>();
  const formApi = useFormApi<UserFormValues>();

  useEffect(() => {
    if (!user) return;
    formApi.setValues({
      ...user,
      position: user?.person?.company?.[0]?.title,
      assigned_branchoffices: user?.person?.branchoffice,
      phone: addPlusToPhone(user?.person?.contact?.find((cntct) => cntct.type === 'mobile_phone')?.value),
    });
  }, [formApi, user]);

  return (
    <div className="flex lg:flex-row flex-col gap-10">
      <div className="flex flex-col gap-2 lg:min-w-[42%]">
        <div className="flex flex-row gap-2 flex-wrap">
          <TyInput
            className="w-full md:flex-1"
            type="text"
            name="person.first_name"
            label="Jméno"
            required
          />
          <TyInput
            className="w-full md:flex-1"
            type="text"
            name="person.last_name"
            label="Příjmení"
            required
          />
        </div>
        <TyInput
          name="username"
          type="email"
          label="Email"
          disabled={!!user?.username}
          required
        />
        <TyInput
          name="phone"
          type="phone"
          label="Telefon"
          validate={validatePhoneNumber}
          validateOn="change"
        />
        <TyInput
          name="position"
          type="text"
          label="Pozice"
          required
        />
        <TySelectRole
          name="role[0]"
          label="Úroveň oprávnění"
          filter={{
            slug:
            [Roles.COMPANY, Roles.BRANCHOFFICE_MANAGER, Roles.BRANCHOFFICE_SUPERVISOR, Roles.SHIFT_SUPERVISOR],
          }}
          required
        />
        {formState.values?.role?.some((x) => x.slug === Roles.ADMIN) ? null : (
          <TySelectBranchoffice
            companyId={companyId}
            name="assigned_branchoffices"
            initialValue={user?.person?.branchoffice}
            label="Přidelené provozovny"
            isMulti
          />
        )}
      </div>
    </div>
  );
};

export default UserForm;
