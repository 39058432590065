import moment from 'moment';

import { ApplicationState, BlockReasonDisplay, BranchofficeType, PerkGroup, PerkId } from './enums';
import { StarIcon } from '../components/icons';

export const getRandomInt = (min, max) => {
  const formattedMin = Math.ceil(min);
  const formattedMax = Math.floor(max);
  return Math.floor(Math.random() * (formattedMax - formattedMin + 1)) + formattedMin;
};

export const firstToUpperCase = ([f, ...rest], locale = navigator.language) =>
  f.toLocaleUpperCase(locale) + rest.join('');

export const removeEmptyDecimals = (number, minFractionDigits = 2, maxFractionDigits = 2) => {
  const withDecimals = new Intl.NumberFormat(
    'cs-CZ',
    { minimumFractionDigits: minFractionDigits, maximumFractionDigits: maxFractionDigits },
  );
  const noDecimals = new Intl.NumberFormat(
    'cs-CZ',
    { minimumFractionDigits: 0, maximumFractionDigits: 0 },
  );

  const n = Number(number);

  return n % 1 !== 0 ? withDecimals.format(n) : noDecimals.format(n);
};

export const getHoursLeftFromNow = (date) => {
  const today = new Date();
  const end = moment(date);
  const duration = moment.duration(end.diff(today));
  return duration.asHours();
};

/**
 * Join two dates using 'date' part from first argument and 'time' part from second argument
 *
 * @prop {(Moment | Date | string)} [date]
 * @prop {(Moment | Date | string)} [time]
 * @returns {Moment}
 */
export const joinDateTime = (date, time) => moment(date)
  .hours(time.hours())
  .minutes(time.minutes())
  .seconds(0)
  .milliseconds(0);

export const getDuration = (start, end, unit, asFloat) => moment(end).diff(start, unit, asFloat);

// TODO: remove this function
/**
 * @deprecated
 */
export function assignBy(key) {
  return (data, item) => {
    // eslint-disable-next-line no-param-reassign
    data[item[key]] = item;
    return data;
  };
}
// TODO: remove this function
/**
 * @deprecated
 */
export const mapById = (key, data) => data.reduce(assignBy(key), {});

export const renderRating = (rating) => (
  <div className="ty-star-rating" style={{ display: 'flex', flexWrap: 'nowrap' }}>
    <StarIcon
      iconcolor={rating >= 1 ? ' #B6CD1E' : null}
      strokecolor={rating >= 1 ? ' #B6CD1E' : null}
    />
    <StarIcon
      iconcolor={rating >= 2 ? ' #B6CD1E' : null}
      strokecolor={rating >= 2 ? ' #B6CD1E' : null}
    />
    <StarIcon
      iconcolor={rating >= 3 ? ' #B6CD1E' : null}
      strokecolor={rating >= 3 ? ' #B6CD1E' : null}
    />
    <StarIcon
      iconcolor={rating >= 4 ? ' #B6CD1E' : null}
      strokecolor={rating >= 4 ? ' #B6CD1E' : null}
    />
    <StarIcon
      iconcolor={rating >= 5 ? ' #B6CD1E' : null}
      strokecolor={rating >= 5 ? ' #B6CD1E' : null}
    />
  </div>
);

export function transformAdminOrdersTable(data) {
  return data.map((item) => ({
    // DB
    id: item.id,
    shift_template_id: item.shift_template_id,
    company_id: item.company_id,
    branchoffice_id: item.branchoffice_id,
    position_id: item.position_id,
    contact_person: item.contact_person,
    display_name: item.display_name,
    payment_base: item.payment_base,
    credits: item.credits,
    invitation_credits: item.invitation_credits,
    contest_start: item.contest_start,
    contest_end: item.contest_end,
    start_time: item.start_time,
    end_time: item.end_time,
    description: item.description,
    instruction: item.instruction,
    instruction_newcomers: item.instruction_newcomers,
    created_at: item.created_at,
    updated_at: item.updated_at,
    deleted_at: item.deleted_at,
    manShift: item.manShift,
    // Extras for table
    day: firstToUpperCase(moment(item.start_time).format('dd')),
    date: moment(item.start_time).format('DD.MM.YYYY'),
    from_time: moment(item.start_time).format('HH:mm'),
    to_time: moment(item.end_time).format('HH:mm'),
    name: item.shiftTemplate?.template_name,
    orders_count: item.orders_count,
    filled_orders_count: item.filled_orders_count,
    unfilled_orders_count: item.unfilled_orders_count,
    invitation_orders_count: item.invitation_orders_count,
    branchoffice_branch: (item.branchoffice?.parent?.type === BranchofficeType.BRANCH
      && item.branchoffice?.parent?.name)
      || (item.branchoffice?.parent?.type === BranchofficeType.BRANCH
        && item.branchoffice?.name) || '-',
    branchoffice_department:
      (item.branchoffice?.type === BranchofficeType.DEPARTMENT
        && item.branchoffice?.name) || '-',
  }));
}
export function transformAdminReportTable(data) {
  return data.map((item) => ({
    id: item.id,
    date: moment(item.shift.start_time).format('DD. MM. YYYY'),
    start_time: moment(item.shift.start_time).format('HH:mm'),
    end_time: moment(item.shift.end_time).format('HH:mm'),
    branchoffice_branch: item.shift.branchoffice?.name,
    branchoffice_department: item.shift.branchoffice?.name,
    person_id: item.person_id,
    person_lastname: item.person.last_name,
    person_firstname: item.person.first_name,
    person_dob: moment(item.person.personData?.birthdate).format('DD. MM. YYYY'),
  }));
}
export function transformAdminCredits(data) {
  return data.map((item) => ({
    id_credit: item.id,
    id_person: item.person.id,
    id_attendance: item.attendance.id,
    person_firstname: item.person.first_name,
    person_lastname: item.person.last_name,
    person_bank_account: item.person.personData.bank_account,
    // TODO: Check if API returns bank code data (example: /0100)
    // person_bank_code: item.person.personData.bank_account,
    created_at: item.created_at,
  }));
}
export function transformAdminApplication(data) {
  return data.map((item) => ({
    id: item.id,
    key: item.id,
    man_shift_id: item.man_shift_id,
    is_invitation: item.invitation,
    credits: item.credits,
    payment_base: item.payment_base,
    state: item.state,
    person_firstname: item.person.first_name,
    person_lastname: item.person.last_name,
    person_id: item.person.id,
    person_mobile: item.person.contact?.mobile,
    person_email: item.person.login?.username,
    person_personData_birthdate: item.person.personData?.birthdate
      ? moment(item.person.personData.birthdate).format('DD.MM. YYYY')
      : '-',
    shift_name: item.shift.name,
    shift_company_name: item.shift.company.name,
    shift_branchoffice_name: item.shift.branchoffice.name,
    shift_start: moment(item.shift.start_time).format('DD.MM. YYYY HH:mm'),
    shift_end: moment(item.shift.end_time).format('DD.MM. YYYY HH:mm'),
    ...item,
  }));
}

const mapPaymentDetails = (item) => ({
  paymentAuthorized_person_id: item.person_id,
  paymentAuthorized_person_first_name: item.person.first_name,
  paymentAuthorized_person_last_name: item.person.last_name,
  paymentAuthorized_person_personData_bank_account: String(
    item.person.personData?.bank_account,
  )?.split('/')[0],
  paymentAuthorized_person_personData_bank_number: String(
    item.person.personData?.bank_account,
  )?.split('/')[1],
  paymentAuthorized_company_name: item.company?.name,
  paymentAuthorized_person_personData_constant_variable:
    item.person.personData?.constant_variable,
});

// TODO: This whole transform fn is kinda pointless, we should just calculate stuff here if needed,
//       there is no need to just rename attributes in object.
export function transformAdminPaymentConfirmation(data) {
  return [
    // map regular payments
    ...data.filter((item) => !!item.paymentAuthorized)
      .map((item) => ({
        id: item.id,
        payment_amount: item.amount,
        ...mapPaymentDetails(item.paymentAuthorized),
        ...item,
      })),
    // map credit payments
    ...data.filter((item) => !!item.creditTransaction)
      .map((item) => ({
        id: item.id,
        payment_amount: item.amount,
        ...mapPaymentDetails(item.creditTransaction),
        ...item,
      })),
  ];
}

export function transformPaymentTransactionToPaymentConfirmation(data) {
  return [
    // map regular payments
    ...data.filter((item) => !!item.paymentRequest?.paymentAuthorized)
      .map((item) => ({
        id: item.id,
        payment_amount: item.paymentRequest?.amount,
        ...mapPaymentDetails(item.paymentRequest?.paymentAuthorized),
        ...item,
      })),
    // map credit payments
    ...data.filter((item) => !!item.paymentRequest?.creditTransaction)
      .map((item) => ({
        id: item.id,
        payment_amount: item.paymentRequest?.amount,
        ...mapPaymentDetails(item.paymentRequest?.creditTransaction),
        ...item,
      })),
  ];
}

export function transformAdminTymberBlocks(data) {
  return data.map((item) => ({
    id: item.id,
    company_name: item.company.name,
    branchoffice_name: item.branchoffice?.name || '-',
    tymber_id: item.person.id,
    tymber_firstname: item.person.first_name,
    tymber_lastname: item.person.last_name,
    note: item.note,
    block_reason: BlockReasonDisplay[item.reason],
    block_date: moment(item.created_at).format('DD.MM. YYYY HH:mm'),
    created_by: `${item.creator.first_name} ${item.creator.last_name} (${item.creator.id})`,
  }));
}

export function transformUnapprovedAttendance(data) {
  const positions = [];
  const branches = [];
  const mappedData = data
    .filter((x) => x.attendance === null && x.state === ApplicationState.CONFIRMED)
    .map((item) => {
      if (item.shift.name && item.shift.name != null) {
        positions.push(item.shift.name);
      }
      if (item.shift.branchoffice_id && item.shift.branchoffice_id != null) {
        branches.push(item.shift.branchoffice_id);
      }
      return {
        id: item.id,
        start_time: item.shift.start_time,
        end_time: item.shift.end_time,
        position: item.shift.name,
        person_firstname: item.person.first_name,
        person_lastname: item.person.last_name,
        person_id: item.person.id,
        person_mobile: item.person.contact?.mobile,
        person_email: item.person.contact?.email,
        shift_name: item.shift.name,
        shift_start: item.shift.start_time,
        shift_end: item.shift.end_time,
        person: item.person,
        rating: getRandomInt(1, 6),
      };
    });

  // Get mapped data, distinct positions and branches for filter options
  return {
    unapprovedAttendance: mappedData,
    positions: [...new Set(positions)],
    branches: [...new Set(branches)],
  };
}
export function transformApprovedAttendance(data) {
  return data
    .filter((x) => x.attendance !== null)
    .map((item) => ({
      company_name: item.shift.company?.name,
      branchoffice_branch: item.shift.branchoffice?.name,
      branchoffice_department: item.shift.branchoffice?.name,
      shift_name: item.shift.name,
      person_firstname: item.person.first_name,
      person_lastname: item.person.last_name,
      date: moment(item.shift.start_time).format('DD. MM. YYYY'),
      start_time: moment(item.shift.start_time).format('HH:mm'),
      end_time: moment(item.shift.end_time).format('HH:mm'),
      duration:
        `${moment(item.shift.end_time).diff(item.shift.start_time, 'hours', true)} h`,
      id: item.attendance?.id,
      created_at: moment(item.created_at).toISOString(),
      confirmedBy: item.attendance?.confirmedBy,
      confirmed_at: item.attendance?.created_at,
      confirmed_time: item.attendance?.confirmed_time,
      job_evaluation: item.attendance?.job_evaluation,
      resolution: item.attendance?.resolution,
      attendance: item.attendance,
      shift: item.shift,
      person: item.person,
      deleted_at: item.deleted_at,
      modified_at: item.modified_at,
    }));
}

export const getRequirementsGroupName = (group) => {
  switch (group) {
    case PerkGroup.SEX: return 'pohlaví';
    case PerkGroup.AGE: return 'věk';
    case PerkGroup.QUALIFICATION: return 'kvalifikační předpoklady';
    case PerkGroup.NATIONALITY: return 'Občanství';
    case 'documents': return 'Dokumenty';
    case 'contracts': return 'Pracovní smlouvy';
    default: return 'Ostatní';
  }
};

export const getPerkNameById = (perkId) => {
  switch (perkId) {
    case PerkId.ANY_GENDER: return 'Pro všechny';
    case PerkId.MAN_ONLY: return 'Jen pro muže';
    case PerkId.WOMAN_ONLY: return 'Jen pro ženy';
    case PerkId.ADULT: return '18+';
    case PerkId.COMPANY_SENIOR: return 'Pracoval pro firmu';
    case PerkId.COMPANY_JUNIOR: return 'Pouze pro nováčky';
    case PerkId.BRANCHOFFICE_SENIOR: return 'Pracoval na pobočce';
    case PerkId.POSITION_SENIOR: return 'Pracoval na pozici';
    case PerkId.ANY_QUALIFICATION: return 'Pro všechny';
    case PerkId.ANY_AGE: return '16+';
    case PerkId.EU_CITIZEN: return 'Občan EU';
    case PerkId.NON_EU_CITIZEN: return 'Cizinec mimo EU';
    case PerkId.NON_UA_PROTECTED: return 'Mimo dočasnou ochranu UA';
    case PerkId.INFORMATION_CARD: return 'Informační karta';
    default: return perkId;
  }
};

export const getTextWidth = (text, element) => {
  if (!text || !element) return undefined;
  const { font, paddingRight, paddingLeft } = window.getComputedStyle(element);
  // re-use canvas object for better performance
  const canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement('canvas'));
  const context = canvas.getContext('2d');
  context.font = font;
  const metrics = context.measureText(text);
  const maxWidth = element.clientWidth - parseFloat(paddingLeft) - parseFloat(paddingRight);
  return metrics.width > maxWidth ? maxWidth : metrics.width;
};
