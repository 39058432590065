import { Roles } from '@tymbe/schema/enums';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import AdminRoute from './adminRoute';
import CompanyAdminRoute from './companyAdminRoute';
import CompanyRoute from './companyRoute';
import ProtectedRoute from './protectedRoute';
import Protect from '../apiClient/Protect';
import ShiftCardExample from '../components/ShiftCard/ShiftCardExample';
import TemporaryAllocationList from '../components/tabs/TemporaryAllocationList';
import PageWrapper from '../components/wrapper/PageWrapper';
import NotFound from '../layouts/pages/NotFound';
import Profile from '../pages/account/index.page';
import DocumentsPage from '../pages/administration/documents/DocumentsPage';
import AdminForeclosuresPage from '../pages/administration/foreclosures';
import EmailDetail from '../pages/administration/mail/@mailId/index.page';
import FailedEmails from '../pages/administration/mail/failed/index.page';
import MailPage from '../pages/administration/mail/index.page';
import QueuedEmails from '../pages/administration/mail/queued/index.page';
import SentEmails from '../pages/administration/mail/sent/index.page';
import MpsvTablePage from '../pages/administration/mpsv/index.page';
import AdminAdministrationPaymentConfirmationPage from '../pages/administration/payment/index.page';
import AdminAdministrationPositionsPage from '../pages/administration/position';
import AdminAdministrationReportingPage from '../pages/administration/reporting';
import AdminAdministrationMankaPage from '../pages/administration/shortages';
import UtilityPage from '../pages/administration/utility/index.page';
import AttendancePage from '../pages/attendance/index.page';
import SignUpConfirmation from '../pages/auth/email-sent/SignUpConfirmation';
import Signin from '../pages/auth/signin';
import Signup from '../pages/auth/signup';
import AdminTymberBlocksPage from '../pages/blocks/index.page';
import ShiftAttendanceModal from '../pages/calendar/components/ShiftAttendanceModal';
import ShiftDetailModal from '../pages/calendar/components/ShiftDetailModal';
import CalendarPage from '../pages/calendar/index.page';
import ShiftAttendancePage from '../pages/calendar/shiftAttendance.page';
import ShiftDetailPage from '../pages/calendar/shiftDetail.page';
import EditBranchoffice from '../pages/company/@companyId/branchoffice/@branchofficeId/index.page';
import CreateBranchoffice from '../pages/company/@companyId/branchoffice/create/index.page';
import BranchofficeList from '../pages/company/@companyId/branchoffice/index.pages';
import EditDepartment from '../pages/company/@companyId/department/@departmentId/index.page';
import CreateDepartment from '../pages/company/@companyId/department/create/index.page';
import DepartmentList from '../pages/company/@companyId/department/index.page';
import EditDocumentTypePage from '../pages/company/@companyId/document-type/@documentTypeId/editor/index.page';
import DocumentTypeFormPage from '../pages/company/@companyId/document-type/@documentTypeId/index.page';
import DocumentTypesList from '../pages/company/@companyId/document-type/index.page';
import EditCompany from '../pages/company/@companyId/edit/index.page';
import CompanyPage from '../pages/company/@companyId/index.page';
import ShiftTemplate from '../pages/company/@companyId/shift-template/@shiftTemplateId/index.page';
import ShiftTemplateList from '../pages/company/@companyId/shift-template/index.page';
import CompanyUserList from '../pages/company/@companyId/user/index.page';
import CreateCompany from '../pages/company/create/index.page';
import CompanyListPage from '../pages/company/index.page';
import AdminTymberMonthlyWage from '../pages/monthly-wage/index.page';
import ShiftDetail from '../pages/shift/@shiftId/index.page';
import ShiftPage from '../pages/shift/index.page';
import TymberListPage from '../pages/tymber/index.page';
import AdminShiftsPage from '../pages-legacy/admin/shift';
import AdminTymberActiveTymbersPage from '../pages-legacy/admin/tymber/activeTymbers';
import AdminTymberLogsPage from '../pages-legacy/admin/tymber/tymberLogs';
import Company from '../pages-legacy/company';
import CreateUser from '../pages-legacy/company/company/createUser/CreateUser';
import EditUser from '../pages-legacy/company/company/createUser/EditUser';
import Shift from '../pages-legacy/company/company/Shift';
import CompanyNameReport from '../pages-legacy/company/orders/CompanyNameReport';
import SelectedShiftsReport from '../pages-legacy/company/orders/SelectedShiftsReport';
import CreatePersonBan from '../pages-legacy/company/userDetail/createBan/CreatePersonBan';
import EditPersonBan from '../pages-legacy/company/userDetail/createBan/EditPersonBan';
import IdentityPage from '../pages-legacy/identity';
import FormExample from '../pages-legacy/landing/FormExample';
import ForgotPassword from '../pages-legacy/password/forgot';
import ResetPassword from '../pages-legacy/password/reset';
import UserPage from '../pages-legacy/user';
import UserBanList from '../pages-legacy/user/bans/UserBanList';
import UserBlocks from '../pages-legacy/user/blocks/UserBlocks';
import UserContactList from '../pages-legacy/user/contacts/UserContactList';
import UserCredits from '../pages-legacy/user/credits/UserCredits';
import UserDocumentsList from '../pages-legacy/user/documents/UserDocumentsList';
import EditUserDetail from '../pages-legacy/user/edit';
import NotesContainer from '../pages-legacy/user/notes';
import ShiftsContainer from '../pages-legacy/user/shifts';
import Utility from '../pages-legacy/user/utility';
import UserWallet from '../pages-legacy/user/wallet/UserWallet';
import Verify from '../pages-legacy/verify';
import ActivateAccount from '../pages-legacy/verify/activateAccount/ActivateAccount';
import ActivateCompanyAccount from '../pages-legacy/verify/activateAccount/ActivateCompanyAcount';
import AlertActivateAccountPage from '../pages-legacy/verify/alertActivateAccount';
import EmailChange from '../pages-legacy/verify/emailChange';
import PaymentInfoChange from '../pages-legacy/verify/paymentInfo';

const Paths = () => {
  const location = useLocation();
  const background = location.state && location.state.background;
  if (background) {
    background.state = { ...background.state, isBackground: true };
  }

  return (
    <>
      <Routes location={background || location}>
        {/* PUBLIC */}
        <Route exact path="/" element={<Signin />} />
        <Route exact path="/signup" element={<Signup />} />
        <Route exact path="/signin" element={<Signin />} />
        <Route exact path="/finish-registration" element={<Signin />} />
        <Route exact path="/verify/email-sent" element={<SignUpConfirmation />} />
        <Route exact path="/alerts/activate-account" element={<AlertActivateAccountPage />} />
        <Route exact path="/identity-validation" element={<ProtectedRoute><IdentityPage /></ProtectedRoute>} />
        {/* NEW ROUTE STRUCTURE NOT YET IN USE */}
        {/* <Route exact path="/auth/signup" element={<Signup />} />
    <Route exact path="/auth/signin" element={<Signin />} />
    <Route exact path="/auth/finish-registration" element={<Signin />} />
    <Route exact path="/auth/email-sent" element={<SignUpConfirmation />} /> */}

        {/* DO NOT DELETE THESE COMMENTED LINES BELOW */}
        {/* PROTECTED */}
        {/* TYMBER ??? */}
        {/* <Route exact path="/home" element={<ProtectedRoute><Home /></ProtectedRoute>} />
    <Route exact path="/profile" element={<ProtectedRoute><Home /></ProtectedRoute>} />
    <Route exact path="/jobs" element={<ProtectedRoute><JobsPage /></ProtectedRoute>} />
    <Route exact path="/my-jobs" element={<ProtectedRoute><MyJobsPage /></ProtectedRoute>} />
    <Route exact path="/wallet" element={<ProtectedRoute><WalletPage /></ProtectedRoute>} />
    <Route exact path="/wallet/credits" element={<ProtectedRoute><CreditsPage /></ProtectedRoute>} />
    <Route exact path="/wallet/credits/survey" element={<ProtectedRoute><CreditsSurveyPage /></ProtectedRoute>} />
    <Route
      exact
      path="/wallet/credits/survey-finished"
      element={<ProtectedRoute><SurveyConfirmationPage /></ProtectedRoute>}
    />
    <Route exact path="/wallet/payment-history" element={<ProtectedRoute><PaymentHistoryPage /></ProtectedRoute>} />
    <Route exact path="/wallet/payment" element={<ProtectedRoute><PaymentPage /></ProtectedRoute>} />
    <Route
      exact
      path="/wallet/payment-finished"
      element={<ProtectedRoute><PaymentConfirmationFinishedPage /></ProtectedRoute>}
    />
    <Route exact path="/docs" element={<ProtectedRoute><DocumentsPage /></ProtectedRoute>} />
    <Route
      exact
      path="/docs/personal-details"
      element={<ProtectedRoute><DocumentsPersonalDetailsPage /></ProtectedRoute>}
    />
    <Route
      exact
      path="/docs/id-authorization"
      element={<ProtectedRoute><DocumentsIdAndAuthorizationPage /></ProtectedRoute>}
    />
    <Route exact path="/docs/contracts" element={<ProtectedRoute><DocumentsContractsPage /></ProtectedRoute>} />
    <Route exact path="/docs/pay-slips" element={<ProtectedRoute><DocumentsPaySlipsPage /></ProtectedRoute>} />
    <Route exact path="/docs/confirmations" element={<ProtectedRoute><DocumentsConfirmationsPage /></ProtectedRoute>} />
    <Route
      exact
      path="/docs/health-certificates"
      element={<ProtectedRoute><DocumentsHealthCertificatesPage /></ProtectedRoute>}
    />
    <Route exact path="/faq" element={<ProtectedRoute><Home /></ProtectedRoute>} />
    <Route exact path="/faq/salary-calc" element={<ProtectedRoute><FaqSalaryCalculationPage /></ProtectedRoute>} />
    <Route exact path="/tymber-preferences" element={<ProtectedRoute><TymberPreferencesPage /></ProtectedRoute>} />
    {/* COMPANY */}
        <Route exact path="/account" element={<CompanyRoute><Profile /></CompanyRoute>} />
        <Route exact path="/shift" element={<CompanyRoute><ShiftPage /></CompanyRoute>} />
        <Route exact path="/shift/create" element={<CompanyRoute><Shift /></CompanyRoute>} />
        <Route exact path="/shift/:shiftId" element={<CompanyRoute><ShiftDetail /></CompanyRoute>} />
        <Route exact path="/attendance" element={<CompanyRoute><AttendancePage /></CompanyRoute>} />
        <Route
          exact
          path="/company/:companyId/branchoffice/:branchofficeId"
          element={<CompanyRoute><EditBranchoffice /></CompanyRoute>}
        />
        <Route
          path="/company/:companyId/department/:departmentId"
          element={<CompanyRoute><EditDepartment /></CompanyRoute>}
        />
        <Route
          path="/company/:companyId/create-company-user/:loginId"
          element={<CompanyRoute><EditUser /></CompanyRoute>}
        />
        <Route
          path="/company/:companyId/document-type/create"
          element={<AdminRoute><DocumentTypeFormPage /></AdminRoute>}
        />
        <Route
          path="/company/:companyId/document-type/:documentTypeId"
          element={<AdminRoute><DocumentTypeFormPage /></AdminRoute>}
        />
        <Route
          path="/company/:companyId/document-type/:documentTypeId/editor"
          element={<AdminRoute><EditDocumentTypePage /></AdminRoute>}
        />
        <Route
          exact
          path="/company/:companyId/branchoffice/create"
          element={<CompanyRoute><CreateBranchoffice /></CompanyRoute>}
        />
        <Route
          path="/company/:companyId/department/create"
          element={<CompanyRoute><CreateDepartment /></CompanyRoute>}
        />
        <Route path="/company/:companyId/create-company-user" element={<CompanyRoute><CreateUser /></CompanyRoute>} />
        <Route
          path="/company/:companyId/shift-template/create"
          exact
          element={<CompanyRoute><ShiftTemplate /></CompanyRoute>}
        />
        <Route
          path="/company/:companyId/shift-template/:shiftTemplateId"
          element={<CompanyRoute><ShiftTemplate /></CompanyRoute>}
        />
        <Route exact path="/company/:companyId" element={<CompanyRoute><CompanyPage /></CompanyRoute>}>
          <Route index element={<Navigate to="branchoffice" replace />} />
          <Route path="branchoffice" element={<BranchofficeList />} />
          <Route path="department" element={<DepartmentList />} />
          <Route path="/company/:companyId/user" element={<CompanyUserList />} />
          <Route path="shift-template" element={<ShiftTemplateList />} />
          <Route path="document-type" element={<AdminRoute><DocumentTypesList /></AdminRoute>} />
          <Route path="temporary-allocation-list" element={<TemporaryAllocationList />} />
        </Route>
        <Route exact path="/user/:id" element={<CompanyRoute><UserPage /></CompanyRoute>}>
          <Route index element={<Navigate to="shifts" replace />} />
          <Route path="shifts" element={<ShiftsContainer />} />
          {/* Maybe in the future */}
          {/* <Route path="payouts" element={<div>Vyplaty</div>} /> */}
          {/* <Route path="attendances" element={<div>Potvrzeni</div>} /> */}
          {/* <Route path="logs" element={<div>Log</div>} /> */}
          <Route path="bans" element={<AdminRoute><UserBanList /></AdminRoute>} />
          <Route path="documents" element={<UserDocumentsList />} />
          <Route path="contacts" element={<UserContactList />} />
          <Route path="notes" element={<NotesContainer />} />
          <Route path="wallet" element={<AdminRoute><UserWallet /></AdminRoute>} />
          <Route path="credits" element={<AdminRoute><UserCredits /></AdminRoute>} />
          <Route path="blocks" element={<CompanyAdminRoute><UserBlocks /></CompanyAdminRoute>} />
          <Route path="utility" element={<AdminRoute><Utility /></AdminRoute>} />
        </Route>
        <Route path="/user/:id/create-ban" element={<CompanyRoute><CreatePersonBan /></CompanyRoute>} />
        <Route path="/user/:id/edit-ban/:banId" element={<CompanyRoute><EditPersonBan /></CompanyRoute>} />
        <Route path="/user/:id/edit" element={<CompanyRoute><EditUserDetail /></CompanyRoute>} />
        <Route exact path="/name-report" element={<CompanyRoute><CompanyNameReport /></CompanyRoute>} />
        <Route exact path="/selected-shifts-report" element={<CompanyRoute><SelectedShiftsReport /></CompanyRoute>} />
        <Route exact path="/tymber" element={<CompanyRoute><TymberListPage /></CompanyRoute>} />
        {/* ADMIN */}
        {/* TYMBER */}
        <Route exact path="/dashboard" element={<AdminRoute><Company /></AdminRoute>} />
        <Route exact path="/monthly-wage" element={<AdminRoute><AdminTymberMonthlyWage /></AdminRoute>} />
        <Route exact path="/admin-tymber-logs" element={<AdminRoute><AdminTymberLogsPage /></AdminRoute>} />
        <Route exact path="/blocks" element={<CompanyRoute><AdminTymberBlocksPage /></CompanyRoute>} />
        <Route exact path="/admin-tymber-active" element={<AdminRoute><AdminTymberActiveTymbersPage /></AdminRoute>} />
        {/* SMENY */}
        <Route exact path="/admin-shift" element={<AdminRoute><AdminShiftsPage /></AdminRoute>} />
        {/* EXEKUCE */}
        <Route exact path="/administration/foreclosures" element={<AdminRoute><AdminForeclosuresPage /></AdminRoute>} />
        {/* ADMINISTRACE */}
        <Route
          exact
          path="/administration/email"
          element={<AdminRoute><MailPage /></AdminRoute>}
        >
          <Route index element={<Navigate to="queued" replace />} />
          <Route path="queued" element={<QueuedEmails />} />
          <Route path="sent" element={<SentEmails />} />
          <Route path="failed" element={<FailedEmails />} />
        </Route>
        <Route
          exact
          path="/administration/email/:mailId"
          element={<Protect auth={[Roles.SUPER_ADMIN, Roles.TYMBE_ADMIN]}><EmailDetail /></Protect>}
        />
        <Route
          exact
          path="/administration/shortages"
          element={<AdminRoute><AdminAdministrationMankaPage /></AdminRoute>}
        />
        <Route
          exact
          path="/administration/payment"
          element={<AdminRoute><AdminAdministrationPaymentConfirmationPage /></AdminRoute>}
        />
        <Route
          exact
          path="/administration/position"
          element={<AdminRoute><AdminAdministrationPositionsPage /></AdminRoute>}
        />
        <Route
          exact
          path="/administration/reporting"
          element={<AdminRoute><AdminAdministrationReportingPage /></AdminRoute>}
        />
        <Route
          exact
          path="/administration/utility"
          element={<AdminRoute><PageWrapper /></AdminRoute>}
        >
          <Route index element={<UtilityPage />} />
        </Route>
        <Route
          exact
          path="/administration/mpsv"
          element={<AdminRoute><PageWrapper /></AdminRoute>}
        >
          <Route index element={<MpsvTablePage />} />
        </Route>
        <Route exact path="/admin-administration-documents" element={<AdminRoute><DocumentsPage /></AdminRoute>} />
        {/* FIRMA */}
        <Route exact path="/company" element={<AdminRoute><CompanyListPage /></AdminRoute>} />
        {/* SETTING */}
        <Route exact path="/company/create" element={<AdminRoute><CreateCompany /></AdminRoute>} />
        <Route exact path="/company/:companyId/edit" element={<AdminRoute><EditCompany /></AdminRoute>} />
        {/* Public routes */}
        <Route exact path="/verify" element={<Verify />} />
        <Route exact path="/verify/activate-account" element={<ActivateAccount />} />
        <Route exact path="/verify/activate-company-account" element={<ActivateCompanyAccount />} />
        <Route exact path="/verify/email-change" element={<EmailChange />} />
        <Route exact path="/verify/payment-info" element={<PaymentInfoChange />} />
        <Route exact path="/forgot-password" element={<ForgotPassword />} />
        <Route exact path="/reset-password" element={<ResetPassword />} />
        {/* EXAMPLES / SANDBOX */}
        <Route exact path="/form-example" element={<FormExample />} />
        <Route exact path="/shiftcard-example" element={<ShiftCardExample />} />
        <Route exact path="/not-found" element={<NotFound />} />
        <Route path="/*" element={<NotFound />} />

        {/* CALENDAR */}
        <Route exact path="/calendar" element={<AdminRoute><PageWrapper /></AdminRoute>}>
          <Route index element={<CalendarPage />} />
          {/* <Route path="dev/*" element={<CalendarDevPage />} /> */}
        </Route>
        <Route element={<AdminRoute><PageWrapper /></AdminRoute>}>
          <Route path="/shiftDetail/:shiftId" element={<ShiftDetailPage />} />
          <Route path="/shiftAttendance/:shiftId" element={<ShiftAttendancePage />} />
        </Route>
      </Routes>
      {background && (
        <Routes>
          <Route path="/shiftDetail/:shiftId" element={<ShiftDetailModal />} />
          <Route path="/shiftAttendace/:shiftId" element={<ShiftAttendanceModal />} />
        </Routes>
      )}
    </>
  );
};

export default Paths;
