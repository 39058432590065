import { isValidBankAccount } from '@tymbe/bank-account';
import { validCzechCountries } from '@tymbe/schema/enums';
import {
  Row,
  Form,
  Col,
  Radio,
  Checkbox,
  Input,
  DatePicker,
  Select,
} from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useIntl } from 'react-intl';
import { useMutation } from 'react-query';

import feathersClient from '../../apiClient';
import { useUser } from '../../apiClient/ApiContext';
import allCountriesInTheWorld from '../../assets/allCountriesInTheWorld.json';
import { ErrorAlert } from '../../components/alerts';
import { PrimaryButton, SecondaryButton, SubmitButton } from '../../components/buttons';
import { ResponsiveGrid } from '../../components/grids';
import Illustration from '../../components/illustration';
import { ChatPhoneIllustration, DoneIllustration, PayInfoChangeIllustration } from '../../components/illustrations';
import { SimpleFormInput } from '../../components/inputs';
import { selectCountryOptions } from '../../components/inputs/TySelectCountry/TySelectCountry.utils';
import validateZipCode from '../../components/inputs/Validators/zipCode';
import LocaleSwitcher from '../../components/LocaleSwitcher';
import Smartsupp from '../../components/Smartsupp';
import { PageSubTitle, PageTitle } from '../../components/texts';
import Container from '../../containers';
import { ContactTypeEnum } from '../../types/TymbeApi';
import { blockInvalidCharactersForZip, formatOnPasteForZip } from '../../utils/zipCode';

const IdentityPage = () => {
  const selectCountryOpts = selectCountryOptions();
  const intl = useIntl();
  const { mutateAsync: updatePersonData } = useMutation(
    ['update-person-data'],
    async ({ body }) => {
      await feathersClient.service('person-data').create(body);
    },
  );

  const { mutateAsync: createPersonContact } = useMutation(
    ['create-person-contact'],
    async (body) => {
      await feathersClient.service('person-contact').create(body);
    },
  );

  const user = useUser();
  const [form] = Form.useForm();
  const [sameAddress, setSameAddress] = useState(true);
  const [foreignPin, setForeignPin] = useState(false);
  const [isFromCzechia, setIsFromCzechia] = useState(true);
  const [currentStep, setCurrent] = useState(0);
  const [defaultCountry, setDefaultCountry] = useState(false);
  const [defaultNationality, setDefaultNationality] = useState(false);

  const fifteenYearsAgo = moment().subtract(15, 'years').subtract(1, 'day');

  const PERSONAL_INFO_FIELDS = [
    'place_of_birth',
    'birthdate',
    'pin',
    'gender',
    'nationality',
  ];
  const BANK_FIELDS = ['bank_account_number', 'payment_note'];
  const ADDRESS_FIELDS = ['addressline1', 'locality', 'zip', 'country'];
  const CONTACT_ADDRESS_FIELDS = ['contact_addressline1', 'contact_locality', 'contact_zip', 'contact_country'];

  const onSetSameAddress = (change) => {
    setSameAddress(change);
  };

  useEffect(() => {
    form.validateFields([
      'contact_addressline1',
      'contact_locality',
      'contact_zip',
      'contact_country',
    ]);
  }, [sameAddress, form]);

  const onFinishFailed = () => {
  };

  const steps = [
    {
      buttontext: intl.formatMessage({
        defaultMessage: 'Vložit údaje',
      }),
      requirement: true,
      content: (
        <div className="flex-auto text-center">
          <div>
            <PageTitle textAlign="center">{intl.formatMessage({
              defaultMessage: 'Proč potřebujeme tvoje osobní údaje?',
            })}
            </PageTitle>
            <div>
              <p>{intl.formatMessage({
                defaultMessage: 'Musíme mít jistotu, že je ti alespoň 16, takže na brigádu můžeš',
              })}
              </p>
            </div>
            <div>
              <p>
                {intl.formatMessage({
                  defaultMessage: 'Když chceš podepsat třeba Dohodu o provedení práce, tyhle údaje jsou nutné',
                })}
              </p>
            </div>
          </div>
          <br />
        </div>
      ),
    },
    {
      requirement: true,
      buttontext: intl.formatMessage({
        defaultMessage: 'Pokračovat',
      }),
      validate: PERSONAL_INFO_FIELDS,
      content: (
        <>
          <PageTitle textAlign="center">{intl.formatMessage({ defaultMessage: 'Osobní údaje' })}</PageTitle>
          <PageSubTitle textAlign="center">
            {intl.formatMessage({
              defaultMessage: 'A teď prosím všechny údaje pečlivě zkontroluj, abychom měli jistotu.',
            })}
          </PageSubTitle>
          <Row style={{ marginBottom: '18px' }} align="center">
            <Col span={24}>
              <h3>{intl.formatMessage({
                defaultMessage: 'Národnost',
              })}
              </h3>
              <Form.Item
                name="nationality"
                rules={[
                  {
                    required: defaultNationality,
                    message: intl.formatMessage({
                      defaultMessage: 'Prosím vložte Vaší národnost.',
                    }),
                  },
                ]}
              >
                <Select
                  placeholder="Národnost"
                  options={selectCountryOpts}
                  defaultValue="CZE"
                  onChange={() => {
                    setDefaultNationality(true);
                  }}
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <h3>
                {intl.formatMessage({
                  defaultMessage: 'Místo narození',
                })}
              </h3>
              <SimpleFormInput
                placeholder={intl.formatMessage({
                  defaultMessage: 'Místo narození',
                })}
                name="place_of_birth"
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({
                      defaultMessage: 'Prosím vložte místo narození.',
                    }),
                  },
                ]}
              />
            </Col>

            <Col span={24}>
              <h3>{intl.formatMessage({
                defaultMessage: 'Datum narození',
              })}
              </h3>
              <Form.Item
                name="birthdate"
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({
                      defaultMessage: 'Prosím vložte datum narození.',
                    }),
                  },
                ]}
              >
                <DatePicker
                  disabledDate={(current) => current && current > fifteenYearsAgo}
                  defaultPickerValue={fifteenYearsAgo}
                  showToday={false}
                  placeholder={intl.formatMessage({
                    defaultMessage: 'Datum narození',
                  })}
                  format={(date) => date.utc().format('DD.MM.YYYY')}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <h3>{intl.formatMessage({
                defaultMessage: 'Rodné číslo',
              })}
              </h3>
              <Row>
                <SimpleFormInput
                  placeholder={intl.formatMessage({
                    defaultMessage: 'Rodné číslo',
                  })}
                  name="pin"
                  rules={[
                    {
                      required: !foreignPin,
                      message: intl.formatMessage({
                        defaultMessage: 'Prosím vložte rodné číslo.',
                      }),
                    },
                    () => ({
                      validator(_, value) {
                        const regex = /\d{2}(0[1-9]|1[0-2]|5[1-9]|6[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]|4[1-9]|5[0-9]|6[0-9]|7[0-9]|8[0-1])\/?\d{3,4}/;
                        if ((regex.test(value) && parseInt(value.replace('/', ''), 10) % 11 === 0) || foreignPin) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error(intl.formatMessage({ defaultMessage: 'Nesprávný formát' })));
                      },
                    }),
                  ]}
                />
              </Row>
              <Form.Item name="foreign_pin">
                <Checkbox
                  style={{ display: 'flex', alignItems: 'center' }}
                  onChange={(change) => setForeignPin(change.target.checked)}
                >
                  {intl.formatMessage({ defaultMessage: 'Nemám české rodné číslo' })}
                </Checkbox>
              </Form.Item>
            </Col>

            <Col offset={1} span={11}>
              <h3>{intl.formatMessage({
                defaultMessage: 'Pohlaví',
              })}
              </h3>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({
                      defaultMessage: 'Povinné pole.',
                    }),

                  },
                ]}
                name="gender"
              >
                <Radio.Group
                  options={[
                    {
                      label: intl.formatMessage({ defaultMessage: 'Muž' }),
                      value: 'male',
                    },
                    {
                      label: intl.formatMessage({ defaultMessage: 'Žena' }),
                      value: 'female',
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
        </>
      ),
    },
    {
      requirement: true,
      buttontext: intl.formatMessage({
        defaultMessage: 'Pokračovat',
      }),
      validate: sameAddress ? ADDRESS_FIELDS : [...ADDRESS_FIELDS, ...CONTACT_ADDRESS_FIELDS],
      content: (
        <Row style={{ marginBottom: '18px' }} align="center">
          <Col span={24}>
            <PageTitle textAlign="center">{intl.formatMessage({ defaultMessage: 'Trvalé bydliště' })}</PageTitle>
          </Col>
          <Col span={24}>
            <h3>{intl.formatMessage({ defaultMessage: 'Adresa' })}</h3>
            <Form.Item
              name="addressline1"
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({ defaultMessage: 'Prosím vložte Ulice a číslo.' }),
                },
              ]}
            >
              <Input placeholder={intl.formatMessage({ defaultMessage: 'Ulice a číslo' })} />
            </Form.Item>
          </Col>
          <Col span={15}>
            <h3>{intl.formatMessage({ defaultMessage: 'Město' })}</h3>
            <Form.Item
              name="locality"
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({ defaultMessage: 'Prosím vložte město.' }),
                },
              ]}
            >
              <Input placeholder={intl.formatMessage({ defaultMessage: 'Město' })} />
            </Form.Item>
          </Col>
          <Col offset={1} span={8}>
            <h3>{intl.formatMessage({ defaultMessage: 'PSČ' })}</h3>
            <Form.Item
              name="zip"
              dependencies={['country']}
              rules={[
                {
                  required: isFromCzechia,
                  message: intl.formatMessage({ defaultMessage: 'Prosím vložte PSČ.' }),
                },
                () => ({
                  validator(_, value) {
                    if (validateZipCode(value, form.getFieldValue('country') ?? '') === undefined) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(intl.formatMessage({ defaultMessage: 'Nesprávný formát PSČ.' })));
                  },
                }),
              ]}
            >
              <Input
                type="number"
                placeholder={intl.formatMessage({ defaultMessage: 'PSČ' })}
                onPaste={formatOnPasteForZip}
                onKeyDown={blockInvalidCharactersForZip}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <h3>{intl.formatMessage({ defaultMessage: 'Země' })}</h3>
            <Form.Item
              name="country"
              initialValue="CZE"
              rules={[
                {
                  required: defaultCountry,
                  message: intl.formatMessage({ defaultMessage: 'Prosím vložte zemi.' }),
                },
              ]}
            >
              <Select
                style={{ width: '63%' }}
                placeholder={intl.formatMessage({ defaultMessage: 'Země' })}
                options={selectCountryOpts}
                onChange={(result, option) => {
                  setDefaultCountry(true);
                  const isCzechia = validCzechCountries.includes(result);
                  setSameAddress(isCzechia);
                  setIsFromCzechia(isCzechia);
                }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <PageTitle textAlign="center">{intl.formatMessage({ defaultMessage: 'Kontaktní adresa' })}</PageTitle>
          </Col>
          {isFromCzechia && (
            <Col span={24}>
              <Form.Item name="same_addresses">
                <Checkbox
                  style={{ display: 'flex', alignItems: 'center' }}
                  checked={sameAddress}
                  onChange={(change) => onSetSameAddress(change.target.checked)}
                >
                  {intl.formatMessage({ defaultMessage: 'Kontaktní adresa je stejná jako trvalé bydliště' })}
                </Checkbox>
              </Form.Item>
            </Col>
          )}
          {!isFromCzechia && (
            <Col>
              <p>
                {intl.formatMessage({
                  defaultMessage:
                    'Protože nejsi z České republiky, je nutné abys zadal kontaktní adresu, která se nachází v ČR',
                })}
              </p>
            </Col>
          )}

          {sameAddress ? null : (
            <>
              <Col span={24}>
                <h3>{intl.formatMessage({ defaultMessage: 'Adresa' })}</h3>
                <Form.Item
                  name="contact_addressline1"
                  rules={[
                    {
                      required: !sameAddress,
                      message: intl.formatMessage({ defaultMessage: 'Prosím vložte Ulice a číslo.' }),
                    },
                  ]}
                >
                  <Input placeholder={intl.formatMessage({ defaultMessage: 'Ulice a číslo' })} />
                </Form.Item>
              </Col>
              <Col span={15}>
                <h3>{intl.formatMessage({ defaultMessage: 'Město' })}</h3>
                <Form.Item
                  name="contact_locality"
                  rules={[
                    {
                      required: !sameAddress,
                      message: intl.formatMessage({ defaultMessage: 'Prosím vložte město.' }),
                    },
                  ]}
                >
                  <Input placeholder={intl.formatMessage({ defaultMessage: 'Město' })} />
                </Form.Item>
              </Col>
              <Col offset={1} span={8}>
                <h3>{intl.formatMessage({ defaultMessage: 'PSČ' })}</h3>
                <Form.Item
                  name="contact_zip"
                  dependencies={['contact_country']}
                  rules={[
                    {
                      required: !sameAddress,
                      message: intl.formatMessage({ defaultMessage: 'Prosím vložte PSČ.' }),
                    },
                    () => ({
                      validator(_, value) {
                        if (validateZipCode(value, form.getFieldValue('contact_country') ?? '') === undefined) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(intl.formatMessage({ defaultMessage: 'Nesprávný formát PSČ.' })),
                        );
                      },
                    }),
                  ]}
                >
                  <Input
                    placeholder={intl.formatMessage({ defaultMessage: 'PSČ' })}
                    onPaste={formatOnPasteForZip}
                    onKeyDown={blockInvalidCharactersForZip}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <h3>{intl.formatMessage({ defaultMessage: 'Země' })}</h3>
                <Form.Item
                  name="contact_country"
                  initialValue="CZE"
                  dependencies={['same_addresses', 'country']}
                  rules={[
                    {
                      required: !sameAddress || !isFromCzechia,
                      message: intl.formatMessage({ defaultMessage: 'Prosím vložte zemi.' }),
                    },
                    { min: 2 },
                  ]}
                >
                  <Select
                    options={selectCountryOpts}
                    placeholder={intl.formatMessage({ defaultMessage: 'Země' })}
                    disabled
                  />
                </Form.Item>
              </Col>
            </>
          )}
        </Row>
      ),
    },
    {
      requirement: true,
      buttontext: intl.formatMessage({ defaultMessage: 'Pokračovat' }),
      validate: BANK_FIELDS,
      content: (
        <Row style={{ marginBottom: '18px' }} align="center">
          <Col span={24}>
            <PageTitle textAlign="center">{intl.formatMessage({ defaultMessage: 'Platební údaje' })}</PageTitle>
          </Col>
          <Col span={24}>
            <Illustration
              extraStyle={{ margin: '25px 0 35px 0' }}
              svg={<PayInfoChangeIllustration />}
            />
          </Col>
          <Col span={24}>
            <h3>{intl.formatMessage({ defaultMessage: 'Číslo účtu' })}</h3>
            <Form.Item
              name="bank_account_number"
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({ defaultMessage: 'Prosím vložte číslo účtu.' }),
                },
                {
                  message: intl.formatMessage({ defaultMessage: 'Zadejte platný bankovní účet.' }),
                  validator: (_, value) => {
                    if (isValidBankAccount(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject();
                  },
                },
              ]}
            >
              <Input placeholder={intl.formatMessage({ defaultMessage: 'Číslo účtu' })} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <h3>{intl.formatMessage({ defaultMessage: 'Poznámka k platbě' })}</h3>
            <Form.Item
              name="payment_note"
              rules={[
                {
                  pattern: '^[a-zA-Z0-9\\s\\-.,!?()#ľščřťžýáíéěôúäňĽŠČŘŤŽÝÁÍÉĚÔÚÄŇ]{0,140}$',
                  message: intl.formatMessage({ defaultMessage: 'Nesprávný formát' }),
                },
              ]}
            >
              <Input.TextArea
                placeholder={intl.formatMessage({ defaultMessage: 'Sem napiš svůj komentář' })}
                style={{ minHeight: '109px' }}
              />
            </Form.Item>
          </Col>
        </Row>
      ),
    },
    {
      buttontext: intl.formatMessage({ defaultMessage: 'Přeskočit' }),
      requirement: true,
      content: (
        <Row style={{ marginBottom: '18px' }} align="center">
          <Col span={24}>
            <PageTitle textAlign="center">{intl.formatMessage({ defaultMessage: 'Telefonní číslo' })}</PageTitle>
          </Col>
          <Col span={24}>
            <PageSubTitle textAlign="center">
              {
              intl.formatMessage({
                defaultMessage:
                  // eslint-disable-next-line max-len
                  'Tvůj telefon potřebujeme znát, abychom tě mohli včas informovat, kdyby se něco změnilo ve tvých brigádách',
              })
            }
            </PageSubTitle>
          </Col>
          <Col span={24}>
            <Illustration
              extraStyle={{ maxHeight: '159px' }}
              svg={<ChatPhoneIllustration />}
            />
          </Col>
          <Col span={24}>
            <h3>{intl.formatMessage({ defaultMessage: 'Telefonní číslo' })}</h3>
            <Form.Item
              name="phone_number"
              rules={[
                {
                  required: true,
                },
                {
                  pattern: '^(?:\\+421|00421)(\\s*9|s*8)\\d{8}$|^(?:\\+420|00420)(\\s*7|s*6)\\d{8}$',
                  message: intl.formatMessage({ defaultMessage: 'Zadejte telefonní číslo ve formátu +420737123456' }),
                },
              ]}
            >
              <Input placeholder={intl.formatMessage({ defaultMessage: 'Telefonní číslo' })} />
            </Form.Item>
          </Col>
        </Row>
      ),
    },
    {
      buttontext: intl.formatMessage({ defaultMessage: 'Ukázat nabídku brigád' }),
      content: (
        <Row align="center" style={{ marginBottom: '35px' }}>
          <PageTitle textAlign="center">{intl.formatMessage({ defaultMessage: 'A je to!' })}</PageTitle>
          <Illustration svg={<DoneIllustration />} />

          <PageSubTitle textAlign="center">
            {intl.formatMessage({ defaultMessage: 'Vítej mezi registrovanými Tymbery :)' })}
            <br />
            {intl.formatMessage({ defaultMessage: 'Nové brigády už na tebe čekají' })}
          </PageSubTitle>
        </Row>
      ),
    },
  ];

  const next = () => {
    if (steps[currentStep].validate) {
      form.validateFields(steps[currentStep].validate)
        .then(() => setCurrent(currentStep + 1));
    } else {
      setCurrent(currentStep + 1);
    }
    ReactGA.event({
      category: 'registration_progress',
      action: `registration_progress_${currentStep}`,
      label: `registration_progress_${currentStep}`, // optional
    });
  };

  const onFinish = async () => {
    const result = form.getFieldsValue(true);

    const [nationalityObject] = allCountriesInTheWorld.filter(({ name }) => name === result.nationality);
    form.validateFields().then(async () => {
      const body = {
        person_id: user.person_id,
        birthplace: result.place_of_birth,
        birthdate: moment(result.birthdate).toISOString(),
        gender: result.gender,
        pin: result.pin?.replace(/\//g, ''),
        bank_account: `${result.bank_account_number}`,
        payment_note: result.payment_note,
        nationality: nationalityObject ? nationalityObject['alpha-3'] : 'CZE',
        permanentAddress: {
          addressline1: result.addressline1,
          locality: result.locality,
          zip: result.zip,
          country: result.country || 'Česká republika',
        },
      };
      if (sameAddress === false) {
        body.contactAddress = {
          addressline1: result.contact_addressline1,
          locality: result.contact_locality,
          zip: result.contact_zip,
          country: result.contact_country || 'Česká republika',
        };
      }

      try {
        await updatePersonData({ person_id: user.person_id, body });
        await createPersonContact(
          {
            person_id: user.person_id,
            type: ContactTypeEnum.MOBILE_PHONE,
            value: result.phone_number.replace(/\s+/g, '').replace(/^\+/, '00'),
            name: 'mobil',
          },
        );
        ReactGA.event({
          category: 'sign_up',
          action: 'sign_up',
          label: 'Finished full registration', // optional
        });
        await feathersClient.logout();

        window.location.assign(import.meta.env.CLIENT_BRIGADY_URL);
        next();
      } catch (e) {
        if (e.code === 403 && e.message === 'duplicate entries') {
          ErrorAlert(
            intl.formatMessage({ defaultMessage: 'Některý ze zadaných údajů již evidujeme u jiného uživatele' }),
          );
        } else {
          ErrorAlert(intl.formatMessage({ defaultMessage: 'Neznámá chyba, kontaktujte support' }));
        }
      }
    });
  };

  const prev = () => {
    setCurrent(currentStep - 1);
  };

  return (
    <Container
      header
      headerFull
      tabMenu
      iconcolor="#B3CA1F"
      background="#F3F5FD"
      showNotifications
      elevate
      showProfile
      showBackButton={false}
    >
      <ResponsiveGrid
        xs={24}
        sm={20}
        md={20}
        lg={18}
        xl={18}
        style={{ marginTop: '2%' }}
        justify="center"
        align="middle"
      >
        <LocaleSwitcher />
        <Form
          hideRequiredMark
          name="signup_form"
          className="ty-form"
          size="large"
          onFinish={onFinish}
          form={form}
          layout="vertical"
          onFinishFailed={onFinishFailed}
        >
          <div className="steps-content">
            <Row
              align="center"
              justify="center"
              style={{ alignItems: 'center' }}
            >
              <Col span={22}>{steps[currentStep].content}</Col>
            </Row>
          </div>
          <Row
            justify="space-around"
            className="steps-action"
            style={{
              display: 'flex',
              flexDirection: 'column-reverse',
              alignItems: 'center',
            }}
          >
            {currentStep > 0 && currentStep !== steps.length - 1 && (
            <SecondaryButton
              style={{ margin: '10px 8px' }}
              onClick={() => prev()}
              buttontext={intl.formatMessage({ defaultMessage: 'Zpět' })}
            />
            )}
            {currentStep < steps.length - 2 && (
            <PrimaryButton
              disabled={
                  !steps[currentStep].requirement
                  || steps[currentStep].requirement === undefined
                }
              type="primary"
              onClick={() => next()}
              buttontext={steps[currentStep].buttontext}
            />
            )}
            {currentStep === steps.length - 2 && (
              <div className="flex flex-col items-center">
                <div className="pb-2">Odesláním formuláře souhlasím s <a href="https://tymbe.cz//pravidla-zpracovani-osobnich-udaju.html"> <u>pravidly o zpracování osobních údajů</u></a></div>
                <SubmitButton
                  onClick={() => {}}
                  buttontext={intl.formatMessage({ defaultMessage: 'Dokončit' })}
                />
              </div>
            )}
            {currentStep === steps.length - 1 && (
            <Row
              justify="space-around"
              className="steps-action"
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <PrimaryButton
                onClick={() => window.location.assign(import.meta.env.CLIENT_BRIGADY_URL)}
                buttontext={intl.formatMessage({ defaultMessage: 'Ukázat nabídku brigád' })}
              />
            </Row>
            )}
          </Row>
        </Form>
      </ResponsiveGrid>
      <Smartsupp />
    </Container>
  );
};

export default IdentityPage;
