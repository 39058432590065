import { Query } from '@feathersjs/feathers';
import { ApplicationState, MpsvReportResponseStatus, MpsvReportStatus, ShiftType } from '@tymbe/schema/enums';
import { MpsvReportData } from '@tymbe/schema/mpsv-report.interface';
import getResponseArray from '@tymbe/utils/getResponseArray';
import moment from 'moment';
import { useState } from 'react';
import Modal from 'react-modal';
import { useIsFetching, useIsMutating, useMutation, useQuery, useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';

import StornoModal from './components/StornoModal';
import { mpsvTableColumnHeaders } from './mpsv.utils';
import feathersClient from '../../../apiClient';
import { getPaginatedResponse } from '../../../apiClient/utils';
import Button from '../../../components/buttons/Button';
import Spinner from '../../../components/Spinner';
import { Table, TableHead, TablePagination, TableRow, TableSortHeader } from '../../../components/Table';
import defaultPageSizeOptions from '../../../components/Table/table.utils';
import { TableRowExpandable } from '../../../components/Table/TableRowExpandable';
import { PageTitle } from '../../../components/texts';
import { RequestBody } from '../../../types/ReactQuery';
import RefreshIcon from '../../calendar/components/icons/RefreshIcon';
import TrashIcon from '../../calendar/components/icons/TrashIcon';
import ViewIcon from '../../calendar/components/icons/ViewIcon';
import styles from '../../calendar/dev/shift-detail/Modal.module.css';

type SelectedRow = {
  application_id: number | undefined;
  mpsvReport_id: string | undefined;
  request_data: string | null | undefined;
};

const MpsvForeignerTablePage = () => {
  const queryClient = useQueryClient();
  const isFetching = useIsFetching();
  const isMutating = useIsMutating();
  const [sortField, setSortField] = useState(mpsvTableColumnHeaders[5].value);
  const [sortAscendingOrder, setSortAscendingOrder] = useState<number>(-1);
  const [paginationStart, setPaginationStart] = useState<number>(0);
  const [paginationPageSize, setPaginationPageSize] = useState<number>(defaultPageSizeOptions[0].value);
  const [openViewRequestModal, setOpenViewRequestModal] = useState(false);
  const [openStornoModal, setOpenStornoModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState<SelectedRow>({ application_id: undefined, mpsvReport_id: undefined, request_data: '' });

  const query: Query = {
    $eager: '[person.personData, employer, mpsvReport, shift.[branchoffice.parent]]',
    $leftJoinRelation: '[person.personData, mpsvReport, shift.branchoffice]',
    'person:personData.nationality': { $nin: ['CZE'] },
    'shift.start_time': { $gte: moment('2024-07-01').startOf('day').toISOString() },
    'shift.type': ShiftType.REGULAR,
    employer_id: { $in: [106, 113, 116, 125] },
    state: ApplicationState.CONFIRMED,
    $sort: { [sortField]: sortAscendingOrder, 'shift.start_time': sortField !== 'shift.start_time' ? -1 : sortAscendingOrder },
  };

  const now = moment().tz('Europe/Prague');
  const { data: errData } = useQuery(
    ['mpsv-application', {
      ...query,
      $or: [{
        'mpsvReport.status': { $in: [MpsvReportStatus.ERROR] },
      }, {
        'mpsvReport.response_status': { $in: [MpsvReportResponseStatus.ERROR, MpsvReportResponseStatus.FAILURE] },
      }, {
        'shift.start_time': {
          $gte: now.startOf('day').toISOString(),
          $lte: now.endOf('day').toISOString(),
        },
        mpsvReport: { $null: true },
      }],
      $limit: -1,
    }] as const,
    async ({ queryKey }) => getResponseArray(await feathersClient
      .service('application')
      .find({ query: queryKey[1] })),
  );

  const { data: okData } = useQuery(
    ['mpsv-application', {
      ...query,
      $or: [{
        'mpsvReport.status': { $nin: [MpsvReportStatus.ERROR] },
        'mpsvReport.response_status': { $nin: [MpsvReportResponseStatus.ERROR, MpsvReportResponseStatus.FAILURE] },
      },
      {
        mpsvReport: { $null: true },
        'shift.start_time': {
          $gte: now.endOf('day').toISOString(),
        },
      },
      {
        mpsvReport: { $null: true },
        'shift.start_time': {
          $lte: now.startOf('day').toISOString(),
        },
      }],
      $skip: paginationStart,
      $limit: paginationPageSize,
    }] as const,
    async ({ queryKey }) => getPaginatedResponse(await feathersClient
      .service('application')
      .find({ query: queryKey[1] })),
  );

  const { mutateAsync: patchMpsvReport } = useMutation(
    ['patch-mpsv-report'],
    (req: RequestBody<MpsvReportData>): Promise<MpsvReportData> =>
      feathersClient.service('mpsv-report').patch(req.id, req.body),
  );

  const { mutateAsync: resendMpsvReport } = useMutation(
    ['resend-mpsv-report'],
    (id: number): Promise<MpsvReportData> =>
      feathersClient.service('mpsv/generate-report').create({ application_id: id }),
  );

  const setSort = (field: string, sortOrder: number) => {
    if (field !== sortField) {
      setSortField(field);
    }
    setSortAscendingOrder(sortOrder);
  };

  const onPageChange = (pageStart: number, pageEnd: number) => {
    setPaginationStart(pageStart);
    setPaginationPageSize(pageEnd - pageStart);
  };

  // const onEditRequest = async ({ mpsvReportId, request_data }: SelectedRow) => {
  //   if (!mpsvReportId || !request_data) return;
  //   await patchMpsvReport({
  //     id: mpsvReportId,
  //     body: {
  //       request_data,
  //     } as MpsvReportData,
  //   });
  //   await queryClient.invalidateQueries('mpsv-application');
  //   setOpenViewRequestModal(false);
  // };

  const onSetStatus = async (mpsvReportId: string | undefined, status: MpsvReportStatus) => {
    if (!mpsvReportId) return;
    await patchMpsvReport({
      id: mpsvReportId,
      body: {
        status,
      } as MpsvReportData,
    });
    await queryClient.invalidateQueries('mpsv-application');
  };

  const onResendMpsvReport = async (applicationId: number | undefined) => {
    if (!applicationId) return;
    await resendMpsvReport(applicationId);
    await queryClient.invalidateQueries('mpsv-application');
  };

  return (
    <>
      <div className="items-center flex justify-between">
        <PageTitle>MPSV Tabulka</PageTitle>
      </div>
      <div className="bg-bg">
        { (errData && okData) ? (
          <Table>
            <TableHead>
              <TableRow>
                {mpsvTableColumnHeaders.map((col) => (
                  <TableSortHeader
                    key={col.label}
                    field={col.sortable ? col.value : undefined}
                    activeField={sortField}
                    onSort={setSort}
                  >
                    {col.label}
                  </TableSortHeader>
                ))}
              </TableRow>
            </TableHead>
            <tbody>
              {errData.map((item) => (
                <TableRowExpandable
                  className="h-10 bg-error-200"
                  key={`${item.id}${item.person_id}${item.employer_id}`}
                  cantExpand={!(item.mpsvReport?.length)}
                  expandColSpan={9}
                  cols={[
                    '❗',
                    <Link to={`/user/${item.person_id}`}>{item.person_id}</Link>,
                    <Link to={`/user/${item.person_id}`}>{`${item.person?.first_name} ${item.person?.last_name}`}</Link>,
                    <Link to={`/company/${item.employer_id}`}>{item.employer?.name}</Link>,
                    moment(item.shift?.start_time).tz('Europe/Prague').format('DD.MM.YYYY'),
                    item.shift?.branchoffice?.parent?.name,
                    item.mpsvReport?.[0]?.status?.toUpperCase?.() ?? '-',
                    (item.mpsvReport && item.mpsvReport?.[0]?.created_at) ? moment(item.mpsvReport?.[0]?.created_at).tz('Europe/Prague').format('DD.MM.YYYY - HH:mm') : '-',
                    <div className="flex gap-2 items-center">
                      { (item.mpsvReport && item.mpsvReport.length) ? (
                        <>
                          {/* { item.mpsvReport?.[0]?.status !== MpsvReportStatus.CHANGE && ( */}
                          {/*  <Button */}
                          {/*    title="Změnit status na 'change'" */}
                          {/*    onClick={(e) => { */}
                          {/*      e.stopPropagation(); */}
                          {/*      onSetStatus(item.mpsvReport?.[0]?.id, MpsvReportStatus.CHANGE); */}
                          {/*    }} */}
                          {/*    className="hover:bg-secondary-400 hover:text-bg bg-secondary-100" */}
                          {/*  ><EditIcon className="w-3" /> */}
                          {/*  </Button> */}
                          {/* ) } */}
                          <Button
                            title="Zobrazení requestu"
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelectedRow({
                                application_id: item.id,
                                mpsvReport_id: item.mpsvReport?.[0]?.id,
                                request_data: item.mpsvReport?.[0]?.request_data,
                              });
                              setOpenViewRequestModal(true);
                            }}
                            className="hover:bg-secondary-400 hover:text-bg bg-secondary-100"
                          >
                            <ViewIcon className="w-3" />
                          </Button>
                          <Button
                            title="Změnit status na 'storno'"
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelectedRow({
                                application_id: item.id,
                                mpsvReport_id: item.mpsvReport?.[0]?.id,
                                request_data: item.mpsvReport?.[0]?.request_data,
                              });
                              setOpenStornoModal(true);
                            }}
                            className="hover:bg-secondary-400 hover:text-bg bg-secondary-100"
                          >
                            <TrashIcon className="w-3" />
                          </Button>
                        </>
                      ) : '' }
                      <Button
                        title="Znovu odeslat request"
                        onClick={(e) => {
                          e.stopPropagation();
                          onResendMpsvReport(item.id);
                        }}
                        className="hover:bg-secondary-400 hover:text-bg bg-secondary-100"
                      >
                        <RefreshIcon className="w-3" />
                      </Button>
                      <Spinner show={!!isFetching || !!isMutating} className="w-4" />
                    </div>,
                  ]}
                >
                  <div className="p-1">
                    <pre>
                      <div className="font-bold text-lg">Response data</div>
                      {(item.mpsvReport && item.mpsvReport?.[0]?.response_data) ? item.mpsvReport?.[0]?.response_data : '-'}
                    </pre>
                    <div className="font-bold text-lg">Error</div>
                    {(item.mpsvReport && item.mpsvReport?.[0]?.error) ? item.mpsvReport?.[0]?.error[0].message : '-'}
                  </div>
                </TableRowExpandable>
              ))}
              {okData.data.map((item) => (
                <TableRowExpandable
                  className="h-10"
                  key={`${item.id}${item.person_id}${item.employer_id}`}
                  cantExpand={!(item.mpsvReport && item.mpsvReport.length)}
                  expandColSpan={9}
                  cols={[
                    '✅',
                    <Link to={`/user/${item.person_id}`}>{item.person_id}</Link>,
                    <Link to={`/user/${item.person_id}`}>{`${item.person?.first_name} ${item.person?.last_name}`}</Link>,
                    <Link to={`/company/${item.employer_id}`}>{item.employer?.name}</Link>,
                    moment(item.shift?.start_time).tz('Europe/Prague').format('DD.MM.YYYY'),
                    item.shift?.branchoffice?.parent?.name,
                    item.mpsvReport?.[0]?.status?.toUpperCase?.() ?? '-',
                    item.mpsvReport?.[0]?.created_at ? moment(item.mpsvReport?.[0]?.created_at).tz('Europe/Prague').format('DD.MM.YYYY - HH:mm') : '-',
                    // <div className="flex gap-2 items-center">
                    //   {(item.mpsvReport
                    //     && item.mpsvReport.length
                    //     && item.mpsvReport?.[0]?.status !== MpsvReportStatus.CHANGE) ? (
                    //       <Button
                    //         title="Změnit status na 'change'"
                    //         onClick={(e) => {
                    //           e.stopPropagation();
                    //           onSetStatus(item.mpsvReport?.[0]?.id, MpsvReportStatus.CHANGE);
                    //         }}
                    //         className="hover:bg-secondary-400 hover:text-bg"
                    //       ><EditIcon className="w-3" />
                    //       </Button>
                    //     ) : '-'}
                    // </div>,
                    <div />,
                  ]}
                >
                  <pre className="p-1">
                    <div className="font-bold text-lg">Response data</div>
                    {item.mpsvReport?.[0]?.response_data ?? null}
                  </pre>
                </TableRowExpandable>
              ))}
            </tbody>
          </Table>
        ) : (
          <Spinner show className="w-10" />
        ) }
      </div>
      <TablePagination
        rowsCount={okData?.total}
        onChangePage={onPageChange}
      />
      <Modal
        isOpen={openViewRequestModal}
        onRequestClose={() => setOpenViewRequestModal(false)}
        shouldCloseOnOverlayClick
        className="bg-bg m-auto rounded-xl px-6 py-4 w-[1000px]"
        overlayClassName={styles.overlay}
        ariaHideApp={false}
      >
        <h2>Request data</h2>
        { /* <textarea
          className="w-full min-h-[200px]"
          value={selectedRow.request_data || ''}
          onChange={(e) => setSelectedRow((prev: SelectedRow) => ({ ...prev, request_data: e.target.value }))}
        /> */ }
        <div
          className="w-full min-h-[200px]"
        >
          {selectedRow.request_data || ''}
        </div>
        <div className="flex justify-end mt-2 gap-2">
          <Button
            onClick={() => setOpenViewRequestModal(false)}
            className="hover:bg-secondary-400 hover:text-bg"
          >
            Zrušit
          </Button>
        </div>
      </Modal>
      { (selectedRow.application_id && openStornoModal)
        && (
          <StornoModal
            isOpen={openStornoModal}
            onRequestClose={() => setOpenStornoModal(false)}
            applicationId={selectedRow.application_id}
            onAccept={() => { onSetStatus(selectedRow.mpsvReport_id, MpsvReportStatus.STORNO); }}
          />
        ) }
    </>
  );
};

export default MpsvForeignerTablePage;
