import { Query } from '@feathersjs/feathers';
import { ApplicationData } from '@tymbe/schema/application.interface';
import classNames from 'classnames';
import Modal from 'react-modal';
import { useQuery } from 'react-query';

import feathersClient from '../../../../apiClient';
import Button from '../../../../components/buttons/Button';
import Spinner from '../../../../components/Spinner';
import styles from '../../../calendar/dev/shift-detail/Modal.module.css';

type StornoModalProps = {
  className?: string,
  applicationId: number,
  isOpen: boolean,
  onRequestClose: (event: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>) => void
  onAccept: () => void;
};

const StornoModal = ({ isOpen, onRequestClose, applicationId, onAccept, className = '' }: StornoModalProps) => {
  const query: Query = {
    $eager: '[mpsvReport]',
    $leftJoinRelation: '[mpsvReport]',
  };
  const { data, isLoading } = useQuery(
    ['storno-modal-application', query],
    ({ queryKey }): Promise<ApplicationData> => (feathersClient
      .service('application')
      .get(applicationId, { query: queryKey[1] as Query })) as Promise<ApplicationData>,
  );
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick
      className={classNames('bg-bg m-auto rounded-xl px-6 py-4 w-[1000px]', className)}
      overlayClassName={styles.overlay}
      ariaHideApp={false}
    >
      <div>
        { !isLoading ? (
          <>
            <h2>Vážně si přejete změnit status na STORNO?</h2>
            <div>
              <span className="font-medium">Aktuální status reportu: </span>{data?.mpsvReport?.[0]?.status || '-'}
            </div>
            <div>
              <span className="font-medium">Aktuální error message: </span>{data?.mpsvReport?.[0]?.error ? data?.mpsvReport?.[0]?.error[0].message : '-'}
            </div>
            <div className="flex justify-end items-center mt-8 gap-2">
              <div className="flex gap-2">
                <Button
                  className="w-12 flex justify-center hover:bg-primary-300"
                  onClick={(e) => {
                    onAccept();
                    onRequestClose(e);
                  }}
                >Ano
                </Button>
                <Button
                  className="w-12 flex justify-center hover:bg-error-300"
                  onClick={onRequestClose}
                >Ne
                </Button>
              </div>
            </div>
          </>
        ) : <Spinner show className="w-12" />}
      </div>
    </Modal>
  );
};

export default StornoModal;
