import { CompanyFulfillmentType } from '@tymbe/schema/enums';
import moment from 'moment/moment';

import { CompanyFormValues } from './components/CompanyForm';
import feathersClient from '../../apiClient';
import { ErrorAlert } from '../../components/alerts';
import { AddressData, CompanyData, DocumentTypeEnum, PersonData } from '../../types/TymbeApi';

export const mapCompanyData = (
  values: Omit<CompanyFormValues, 'address_id'> & { address_id?: number },
): CompanyData => {
  const companyData: CompanyData = {
    state: values.stateOption.value,
    name: values.name,
    cin: values.cin,
    display_name: values.display_name,
    tin: values.tin,
    file_number: values.file_number,
    bank_account: values.bank_account,
    payment_bank_account: values.payment_bank_account,
    note: values.note,
    fulfillment_type: values.typeOption?.value,
    address: {
      id: values?.address_id,
      country: values?.countryOption?.value,
      addressline1: values?.address?.addressline1,
      locality: values?.address?.locality,
      zip: values?.address?.zip,
    } as AddressData,
  } as CompanyData;

  // Map contact person
  if (values.contactPerson) {
    if (values.contactPerson.id === 0) {
      companyData.contactPerson = {
        first_name: values.contactPerson.first_name,
        last_name: values.contactPerson.last_name,
      } as PersonData;
    } else {
      companyData.contact_person = String(values.contactPerson.id);
    }
  }

  // Map contractors
  if (
    values.typeOption?.value !== CompanyFulfillmentType.MANDATE
    && values.contractor
  ) {
    let contractors: CompanyData[] = [];
    if (!Array.isArray(values.contractor)) {
      contractors.push(values.contractor);
    } else {
      contractors = values.contractor;
    }
    companyData.contractor = contractors.map((x) => (
      { id: x.id } as CompanyData
    ));
  } else {
    companyData.contractor = [];
  }

  return companyData;
};

const documentTypeService = feathersClient.service('document-type');

interface Contract {
  name: string,
  type: DocumentTypeEnum,
  templateType: DocumentTypeEnum,
}

const DEFAULT_DPC: Contract = {
  name: 'DPČ',
  type: DocumentTypeEnum.CONTRACT_DPC,
  templateType: DocumentTypeEnum.CONTRACT_DPC_TEMPLATE,
};

const DEFAULT_DPP: Contract = {
  name: 'DPP',
  type: DocumentTypeEnum.CONTRACT_DPP,
  templateType: DocumentTypeEnum.CONTRACT_DPP_TEMPLATE,
};

export const createDefaultContract = async (companyId: number, fulfillmentType: CompanyFulfillmentType) => {
  const documentDefaults = fulfillmentType === CompanyFulfillmentType.AGENCY ? DEFAULT_DPC : DEFAULT_DPP;

  const query = {
    type: documentDefaults.templateType,
    published_at: {
      $lt: moment().toISOString(),
    },
  };

  const documentTypeResp = await documentTypeService.find({ query })
    .catch(() => ErrorAlert(`Nastala chyba. Nepodařilo se nalézt vzorovou šablonu pro ${documentDefaults.name}.`));
  if (!documentTypeResp?.data?.[0]?.template_id) {
    return ErrorAlert('Nastala chyba. Chybí id šablony.');
  }
  const [documentType] = documentTypeResp.data;

  return documentTypeService.create({
    name: documentDefaults.name,
    company_id: companyId,
    is_signable: documentType.is_signable,
    type: documentDefaults.type,
    template_id: documentType.template_id,
    published_at: moment().toISOString(),
    validity_start_at: documentType.validity_start_at,
    validity_end_at: documentType.validity_end_at,
    validity_duration: documentType.validity_duration,
    validity_duration_unit: documentType.validity_duration_unit,
  }).catch(async () => {
    ErrorAlert(`Nastala chyba. Nepodařilo se vytvořit záznam o firemní šabloně pro ${documentDefaults.name}.`);
  });
};
