import { PaymentRequestData } from '@tymbe/schema/payment-request.interface';
import { isValidBankAccount } from '@tymbe/bank-account';
import moment from 'moment';
import Tooltip from 'rc-tooltip';
import { Link } from 'react-router-dom';

import { ExclamationCircleIcon } from '../../../../../components/icons';
import { TableCell, TableRow } from '../../../../../components/Table';
import { dangerColor } from '../../../../../utils/colors';

function getBankAccountPart(
  creditTransaction: PaymentRequestData['creditTransaction'],
  paymentAuthorized: PaymentRequestData['paymentAuthorized'],
  position: number,
) {
  const creditTransBA = creditTransaction?.person?.personData?.bank_account;
  const paymentAuthBA = paymentAuthorized?.person?.personData?.bank_account;
  const bankAccount = creditTransBA || paymentAuthBA;

  if (!bankAccount || !isValidBankAccount(bankAccount)) {
    return (
      <Tooltip overlay="Nevalidní číslo účtu">
        <span className="px-1">
          <ExclamationCircleIcon iconcolor={dangerColor} />
        </span>
      </Tooltip>
    );
  }

  return bankAccount.split('/')[position];
}

function renderDate(datetime: string) {
  return moment(datetime).format('HH:mm DD.MM.YYYY');
}

interface PaymentRequestRowProps {
  paymentRequest: PaymentRequestData;
  onChange: (rowId: number) => void;
  checked: boolean;
}

const PaymentRequestRow = ({
  paymentRequest: {
    id,
    paymentTransaction,
    created_at,
    amount,
    paymentAuthorized,
    credit_payout,
    creditTransaction,
  },
  checked,
  onChange,
}: PaymentRequestRowProps) => {
  const person = (paymentAuthorized || creditTransaction)?.person;
  return (
    <TableRow>
      <TableCell>
        <input
          type="checkbox"
          className="w-5 h-5"
          onChange={() => onChange(id)}
          checked={checked}
        />
      </TableCell>
      <TableCell>{credit_payout ? 'Kredity' : 'Peníze'}</TableCell>
      <TableCell>
        <Link
          to={{
            pathname: `/user/${person?.id}`,
          }}
          className="ty-poppins-12"
        >
          {person?.id}
        </Link>
      </TableCell>
      <TableCell>{person?.first_name}</TableCell>
      <TableCell>{person?.last_name}</TableCell>
      <TableCell>
        {getBankAccountPart(creditTransaction, paymentAuthorized, 0)}
      </TableCell>
      <TableCell>
        {getBankAccountPart(creditTransaction, paymentAuthorized, 1)}
      </TableCell>
      <TableCell>{paymentAuthorized?.company?.name}</TableCell>
      <TableCell>{amount}</TableCell>
      <TableCell>{renderDate(created_at)}</TableCell>
      <TableCell>
        {paymentTransaction?.exported_at
          ? renderDate(paymentTransaction.exported_at)
          : '-'}
      </TableCell>
    </TableRow>
  );
};
export default PaymentRequestRow;
