import { PaySupplementRules } from '@tymbe/schema/pay-supplement.interface';
import { PaySupplementType } from '@tymbe/legislatives/PaySupplementType';
import { Relevant } from 'informed';

import PaySupplementItem from './PaySupplementItem';
import TyCheckbox from '../inputs/TyCheckbox';

interface PaySupplementFormValues {
  $paySupplement: boolean;
}

interface PaySupplementProps {
  isDisabled: boolean;
  name: string;
  initialValues: PaySupplementRules | null;
}

const PaySupplement = ({
  isDisabled,
  name,
  initialValues,
}: PaySupplementProps) => (
  <div className="grid">
    <TyCheckbox
      name="$paySupplement"
      label="Příplatky"
      disabled={isDisabled}
      defaultValue={Boolean(initialValues)}
    />
    <Relevant<PaySupplementFormValues>
      when={({ formState }) => formState.values.$paySupplement}
    >
      <PaySupplementItem
        type={PaySupplementType.Holiday}
        name={`${name}.Holiday`}
        label="Za svátky"
        initialValues={initialValues?.Holiday}
        isDisabled={isDisabled}
      />
      <PaySupplementItem
        type={PaySupplementType.Weekend}
        name={`${name}.Weekend`}
        label="Za víkendy"
        initialValues={initialValues?.Weekend}
        isDisabled={isDisabled}
      />
      <PaySupplementItem
        type={PaySupplementType.Night}
        name={`${name}.Night`}
        label="Za práci v noci"
        initialValues={initialValues?.Night}
        isDisabled={isDisabled}
      />
    </Relevant>
  </div>
);

export default PaySupplement;
