import { OutputData } from '@editorjs/editorjs';
import { PaySupplement } from '@tymbe/legislatives/PaySupplement';
import {
  CompanyFulfillmentType,
  CountryCodes,
  ForeignerOrigin,
  Gender,
  ShiftType,
  EuEeaSwitzCountryCode,
  DocumentType,
  DocumentCategory,
  DocumentValidityStart,
  DocumentValidityEnd,
  DocumentValidityDurationUnit,
} from '@tymbe/schema/enums';
import { JobClassificationIds } from '@tymbe/schema/job-classification-ids.interface';
import { MpsvReportData } from '@tymbe/schema/mpsv-report.interface';
import { PaySupplementRules } from '@tymbe/schema/pay-supplement.interface';
import { PersonPerksData } from '@tymbe/schema/person-perks.interface';
import { BlockedCompany } from '@tymbe/schema/person.interface';
import { UtilityData } from '@tymbe/schema/utility.interface';
import { JsonLayout } from '@tymbe/ty-docs/types/jsonDocument';
import { isPaginated } from '@tymbe/utils/isPaginated';
import { Moment } from 'moment';

import { AttendanceResolution, ContactType, Roles } from '../utils/enums';

export { isPaginated };

export type Pagination = { $skip: number, $limit?: number, $modify?: string };

interface TimeStampsData {
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}

export interface AddressData extends TimeStampsData {
  id: number;
  addressline1: string;
  addressline2: string | null;
  addressline3: string | null;
  locality: string | null;
  region: string | null;
  zip: string | null;
  country: CountryCodes | null;
}

export const enum CompanyState {
  PENDING = 'pending',
  ACTIVE = 'active',
  BANNED = 'banned',
}

export interface CompanyData extends TimeStampsData {
  id: number;
  address_id: number;
  contact_person: string;
  name: string;
  display_name: string;
  cin: string;
  tin: string;
  file_number: string;
  bank_account: string;
  payment_bank_account: string;
  logo: string;
  signature: string;
  stamp: string;
  note: string;
  state: CompanyState;
  fulfillment_type: CompanyFulfillmentType;
  is_readonly: boolean;

  contractor?: CompanyData[];
  contactPerson?: PersonData;
  address?: AddressData;
  branchoffice?: BranchofficeData[];
}

/**
 * @deprecated
 */
export const enum BranchofficeType {
  AREA = 'area',
  BRANCH = 'branch',
  DEPARTMENT = 'department',
}

export interface BranchofficeData extends TimeStampsData {
  id: number;
  external_id: string | null;
  company_id: number;
  parent_id: number | null;
  address_id: number | null;
  contact_person: number | null;
  name: string;
  display_name: string;
  note: string;
  instruction: string;
  signature: string;
  stamp: string;
  type: BranchofficeType | null;

  parent?: BranchofficeData;
  address?: AddressData;
  contactPerson?: PersonData;
  child?: BranchofficeData[];
}

export const enum DocumentTypeEnum {
  ID_CARD = 'id_card',
  HEALTH_CARD = 'health_card',
  CRIMINAL_RECORD = 'criminal_record',
  DRIVING_LICENCE = 'driving_license',
  CONTRACT_DPP = 'contract_dpp',
  CONTRACT_DPC = 'contract_dpc',
  CONTRACT_HPP = 'contract_hpp',
  TYMBE_CONTRACT = 'tymbe_contract',
  CONTRACT_DPP_TEMPLATE = 'contract_dpp_template',
  CONTRACT_DPC_TEMPLATE = 'contract_dpc_template',
  CONTRACT_HPP_TEMPLATE = 'contract_hpp_template',
  DODP_TEMPLATE = 'dodp_template',
  PINKIE = 'pinkie',
  OTHER = 'other',
  MEDICAL_EXAMINATION = 'medical_examination',
}

export interface PositionData extends TimeStampsData {
  id: number;
  name: string;
}

export interface PerkData extends TimeStampsData {
  id: PerkId;
  icon: string | null;
  group?: PerkGroup | null;
  description: string | null;
  is_visible: boolean;
}

export const enum PerkId {
  ANY_GENDER = 'any_gender',
  MAN_ONLY = 'man_only',
  WOMAN_ONLY = 'woman_only',
  ADULT = 'adult',
  ANY_AGE = 'any_age',
  ANY_QUALIFICATION = 'any_qualification',
  COMPANY_SENIOR = 'company_senior',
  COMPANY_JUNIOR = 'company_junior',
  BRANCHOFFICE_SENIOR = 'branchoffice_senior',
  POSITION_SENIOR = 'position_senior',
  EU_CITIZEN = 'eu_citizen',
  /** @deprecated */
  NON_EU_CITIZEN = 'non_eu_citizen',
  NON_UA_PROTECTED = 'non_ua_protected',
}

export enum PerkGroup {
  SEX = 'sex',
  AGE = 'age',
  QUALIFICATION = 'QUALIFICATION',
}

export const isPerkData = (arg: unknown): arg is PerkData =>
  typeof arg === 'object'
  && !!arg && Object.hasOwn(arg, 'id')
  && typeof arg.id === 'string';

export interface DocumentTypeData extends TimeStampsData {
  id: number;
  company_id: number | null;
  branchoffice_id: number | null;
  is_signable: boolean;
  name: string;
  display_name: string | null;
  icon: string | null;
  template_id: string | null;
  type: DocumentType;
  published_at: string | null;
  validity_start_at?: DocumentValidityStart | null;
  validity_end_at?: DocumentValidityEnd | null;
  validity_duration?: number | null;
  validity_duration_unit?: DocumentValidityDurationUnit | null;
  json: (OutputData & { options: { layout: JsonLayout } }) | string | null;
  category: DocumentCategory | null;

  branchoffice: BranchofficeData | null;
}

export const isDocumentTypeData = (arg: unknown): arg is DocumentTypeData =>
  typeof arg === 'object' && !!arg && Object.hasOwn(arg, 'is_signable');

export interface ShiftTemplateData extends TimeStampsData {
  id: number;
  company_id: number;
  position_id: number | null;
  template_name: string;
  name: string;
  payment_base: number;
  credits: number;
  credits_min: number;
  invitation_credits: number;
  billing_rate: number;
  margin: number;
  description: string;
  instruction: string;
  instruction_newcomers: string;
  publish_at: Moment | string | null;
  pay_supplement: PaySupplementRules;
  job_classification_ids: JobClassificationIds;
  expected_hpp_weekly_hours?: number | null;
  maximum_hpp_trial_period?: number | null;
  labels: string[] | null;
  emoji: string | null;

  company?: CompanyData;
  branchoffice?: BranchofficeData[];
  position?: PositionData;
  perk?: PerkData[];
  documentType?: DocumentTypeData[];
  utility?: UtilityData[] | null;
}

export interface ManShiftData extends TimeStampsData {
  id: number;
  external_id?: string | null;
  shift_id: number;
  application_id: number | null;

  shift?: ShiftData;
  application?: ApplicationData;
  canceledApplication?: ApplicationData[];
  perk?: PerkData[];
}

export interface ShiftData extends TimeStampsData {
  id: number;
  external_id?: string | null;
  shift_template_id?: number;
  company_id: number;
  branchoffice_id: number;
  position_id: number | null;
  contact_person?: string | null;
  name: string;
  payment_base: number;
  invitation_credits: number;
  credits: number;
  credits_min: number;
  billing_rate: number;
  margin: number;
  contest_start: string;
  contest_end: string;
  start_time: string;
  end_time: string;
  description: string;
  instruction: string | null;
  instruction_newcomers: string | null;
  pay_supplement: PaySupplementRules;
  job_classification_ids: JobClassificationIds | null;
  surcharges: PaySupplement[];
  expected_hpp_weekly_hours?: number | null;
  maximum_hpp_trial_period?: number | null;
  type: ShiftType;
  emoji: string | null;

  manShift?: ManShiftData[];
  branchoffice?: BranchofficeData;
  department?: BranchofficeData;
  address?: AddressData;
  company?: CompanyData;
  shift_template?: ShiftTemplateData;
  perk?: PerkData[];
  utility?: UtilityData[] | null;
  documentType?: DocumentTypeData[];
  position?: PositionData;
  shiftTemplate?: ShiftTemplateData;
  application?: ApplicationData[];

  orders_count?: number;
  filled_orders_count?: number;
  invitation_orders_count?: number;
  unfilled_orders_count?: number;
}

export interface PersonLiabilityData extends TimeStampsData {
  id: number;
  person_id: number;
  attendance_id: number;
  amount?: number;
  credit_transaction_id?: number | null;
  description?: string | null;

  person?: PersonData;
  attendance?: AttendanceData;
  creditTransaction?: CreditTransactionData;
}

export interface PersonData extends TimeStampsData {
  id: number;
  prefix_name: string | null;
  first_name: string;
  middle_name: string | null;
  last_name: string;
  suffix_name: string | null;
  note?: PersonNote[];

  company: Array<CompanyData & { title: string; }>;
  branchoffice?: Array<BranchofficeData & { title: string; }>;
  login?: LoginData;
  personData?: PersonDataData;
  activeBan?: PersonBanData;
  application?: ApplicationData[];
  accountState?: PersonAccountState;
  personPerks?: PersonPerksData;

  personDocument?: PersonDocumentData[];
  contact?: PersonContactData[];
  blockedCompany?: BlockedCompany[];
}

export const enum PersonAccountStateEnum {
  ADMIN = 'admin',
  COMPANY = 'company',
  BAN = 'ban',
  UNVERIFIED_ACCOUNT = 'unverified_account',
  UNFINISHED_REGISTRATION = 'unfinished_registration',
  UNVERIFIED_PROOF_OF_IDENTITY = 'unverified_proof_of_identity',
  ACTIVE = 'active',
}

export interface PersonAccountState {
  id: number;
  account_state:
    PersonAccountStateEnum.ADMIN |
    PersonAccountStateEnum.COMPANY |
    PersonAccountStateEnum.BAN |
    PersonAccountStateEnum.UNVERIFIED_ACCOUNT |
    PersonAccountStateEnum.UNFINISHED_REGISTRATION |
    PersonAccountStateEnum.UNVERIFIED_PROOF_OF_IDENTITY |
    PersonAccountStateEnum.ACTIVE
}

export const enum ContactTypeEnum {
  EMAIL = 'email',
  MOBILE_PHONE = 'mobile_phone',
  LANDLINE = 'landline',
  OTHER = 'other',
}

export interface CompanyBlockedUserData extends TimeStampsData {
  company_id: number;
  person_id: number;
  note?: string | null;
  created_by?: number;

  company?: CompanyData;
  person?: PersonData;
  creator?: PersonData;
}

export interface PersonNote extends TimeStampsData {
  id: number;
  person_id: number;
  created_by: number;
  note: string;

  createdBy?: PersonData;
}

export interface PersonBanData extends TimeStampsData {
  id: number;
  person_id: number;
  note: string;
  description?: string;
  start_time?: string;
  end_time?: string | null;
  created_by: number;

  person?: PersonData;
  creator?: PersonData;
}
export interface MonthlyWageData {
  year: number;
  month: number;
  employer_id: number;
  person_id: number;
  first_name: string;
  last_name: string;
  worked_time: string;
  wage: string;
  tax: string;
  netwage: string;
  total_records: number;
  first_worked_day: string;

  person?: PersonData;
  employer?: CompanyData;
}

export interface PersonDataData extends TimeStampsData {
  person_id: number;
  birthplace: string | null;
  birthdate: string | null;
  nationality: CountryCodes | null;
  family_member_nationality: EuEeaSwitzCountryCode | null;
  gender: Gender | null;
  pin: string | null;
  permanent_address: number | null;
  contact_address?: number | null;
  bank_account?: string | null;
  swift?: string | null;
  iban?: string | null;
  payment_note?: string | null;
  payment_variable?: string | null;
  payment_specific?: string | null;
  outstanding_liabilities?: number;
  pending_payments?: number;
  credit_balance?: number;
  job_evaluation: number;
  foreigner_origin?: ForeignerOrigin | null;
  subcontractor_allowed: boolean;

  applicationCount?: number;
  attendanceCount?: number;

  person?: PersonData;
  permanentAddress?: AddressData;
  contactAddress?: AddressData;
  personPerks?: PersonPerksData;
}

export interface AttendanceData extends TimeStampsData {
  id: number;
  application_id: number;
  confirmed_time: number;
  confirmed_overtime: number;
  confirmed_credit_time: number;
  overtime: number;
  confirmed_by: number;
  job_evaluation?: number | null;
  resolution: AttendanceResolution;
  note: string | null;

  application?: ApplicationData;
  confirmedBy?: PersonData;
  paymentRequest?: PaymentRequestData;
  paymentAuthorized?: PaymentAuthorizedData[];
  creditTransaction?: CreditTransactionData;
  surveyAnswer?: SurveyAnswerData[];
  personLiability?: PersonLiabilityData;
}

export interface PaymentAuthorizedData extends TimeStampsData {
  id: number;
  person_id: number;
  company_id: number;
  date_time: string;
  value: number;
  attendance_id: number | null;
  payment_request_id: number | null;
  note: string | null;

  attendance?: AttendanceData;
  paymentRequest?: PaymentRequestData;
  person?: PersonData;
  company?: CompanyData;
}

export interface SurveyAnswerData extends TimeStampsData {
  id: number;
  survey_id: number;
  survey_question_id: number;
  survey_question_option_id: number | null;
  person_id: number;
  attendance_id: number | null;
  other?: string | null;

  survey?: SurveyData;
  question?: SurveyQuestionData,
  option?: SurveyQuestionOptionData,
  person?: PersonData,
  attendance?: AttendanceData,
}

export interface SurveyData extends TimeStampsData {
  id: number;
  company_id?: number | null;
  name: string;
  publish_at: string | null;

  company?: CompanyData;
  questions?: SurveyQuestionData[];
}

export interface SurveyQuestionData extends TimeStampsData {
  id: number;
  survey_id: number;
  question: string;
  description?: string | null;
  type?: SurveyQuestionType;
  required?: boolean;
  other_allowed?: boolean;
  survey_option_group_id?: number | null;

  survey?: SurveyData;
  optionGroup?: SurveyOptionGroupData;
  options?: SurveyQuestionOptionData[];
}
export interface SurveyQuestionOptionData extends TimeStampsData {
  id?: number;
  survey_option_group_id: number;
  name: string;
  description: string | null;
  optionGroup?: SurveyOptionGroupData;
}

export const enum SurveyQuestionType {
  TEXT = 'text',
  LONG_TEXT = 'longtext',
  RADIO = 'radio',
  CHECKBOX = 'checkbox',
  DROPDOWN = 'dropdown',
}
export interface SurveyOptionGroupData extends TimeStampsData {
  id: number;
  name: string;
  questionOption?: SurveyQuestionOptionData[];
}
export interface CreditTransactionData extends TimeStampsData {
  id: number;
  person_id: number;
  attendance_id: number | null;
  payment_request_id: number | null;
  amount?: number | string;
  note?: string | null;

  person?: PersonData;
  attendance?: AttendanceData;
  paymentRequest?: PaymentRequestData;
}

export interface PersonDocumentFileData extends TimeStampsData {
  id: number;
  person_document_id: number;
  file: string;

}

export interface PersonContactData extends TimeStampsData {
  id: number;
  person_id: number;
  type: ContactType;
  name: string | null;
  value: string | null;

  person?: PersonData;
}

export interface PaymentRequestData extends TimeStampsData {
  id: number;
  attendance_id?: number | null;
  amount: number;
  credit_payout?: boolean;

  attendance?: AttendanceData;
  paymentTransaction?: PaymentTransactionData;
}

export interface PaymentTransactionData extends TimeStampsData {
  id: number;
  person_id: number;
  payment_request_id: number | null;
  amount?: number;
  bank_account: string;
  payment_constant?: string | null;
  payment_variable?: string | null;
  payment_specific?: string | null;
  payment_note?: string | null;
  exported_at: string | null;

  person?: PersonData;
  paymentRequest?: PaymentRequestData;
}
export interface PersonDocumentData extends TimeStampsData {
  id: number;
  person_id: number;
  document_type_id: number;
  company_id: number | null;
  branchoffice_id: number | null;
  valid_from: string;
  valid_to: string;
  contract_valid_to: string | null;
  name: string;
  did: string;
  approved: boolean | null;
  reviewed_at: string | null;
  reviewed_by: number | null;

  personDocumentFile?: PersonDocumentFileData[];
  person?: PersonData;
  documentType?: DocumentTypeData;
  company?: CompanyData;
  branchoffice?: BranchofficeData;
  reviewedBy?: PersonData;
}

export interface ApplicationData extends TimeStampsData {
  id: number;
  man_shift_id: number;
  person_id: number;
  payment_base: number;
  employer_id: number;
  credits: number;
  invitation: boolean;
  state: ApplicationState | null;

  manShift?: ManShiftData;
  isCompanyJunior?: boolean;
  manShiftReverse?: ManShiftData;
  person?: PersonData;
  shift?: ShiftData;
  attendance?: AttendanceData;
  employer?: CompanyData;
  mpsvReport?: MpsvReportData[];
}

export const isApplicationData = (arg: unknown): arg is ApplicationData =>
  typeof arg === 'object'
  && !!arg && Object.hasOwn(arg, 'man_shift_id')
  && typeof arg.man_shift_id === 'number';

export const enum ApplicationState {
  CONFIRMED = 'confirmed',
  REJECTED = 'rejected',
  SYSTEM_CANCELED = 'system_canceled',
  CANCELED_EARLY = 'canceled_early',
  CANCELED_LATE = 'canceled_late',
}
export interface RolesData extends TimeStampsData {
  id: number;
  name: string | null;
  slug: Roles;
  description: string | null;
}

export interface LoginData extends TimeStampsData {
  id: number;
  person_id: number;
  username: string;
  password: string;
  is_verified?: boolean;
  verify_token?: string | null;
  verify_expire?: string | null;
  reset_token?: string | null;
  reset_expire?: string | null;
  last_login?: string | null;
  verified_at?: string | null;

  role?: Partial<RolesData>[];
  person?: PersonData;
}

export interface AuthenticationResult {
  // type of result returned by app.authenticate() and app.reAuthenticate()
  user: LoginData & {
    person: PersonData;
    role: RolesData[];
  };
}

export const hasTableProp = (data: unknown): data is { table: string; } =>
  !data || (typeof data !== 'object') || !Object.hasOwn(data, 'table') || typeof data.table === 'string';
