import styles from './SignUpConfirmation.module.css';
import { FixedGrid, ResponsiveGrid } from '../../../components/grids';
import Illustration from '../../../components/illustration';
import { EmailIllustration } from '../../../components/illustrations';
import Smartsupp from '../../../components/Smartsupp';
import { SimpleHeading3Text } from '../../../components/texts';
import Container from '../../../containers';

const SignUpConfirmation = () => (
  <Container
    header
    contentstyle={{ marginTop: '4%' }}
    iconcolor="#B3CA1F"
    background="#F3F5FD"
    footer={false}
  >
    <Smartsupp />
    <ResponsiveGrid xs={24} xl={8}>
      <div className={styles.ty_confirmation_container}>
        <FixedGrid>
          <SimpleHeading3Text>Zaslali jsme Vám registrační email</SimpleHeading3Text>
        </FixedGrid>
        <Illustration svg={<EmailIllustration />} />
        <SimpleHeading3Text>Pro dokončení registrace klikněte na odkaz v mailu  </SimpleHeading3Text>
      </div>

    </ResponsiveGrid>
  </Container>
);

export default SignUpConfirmation;
